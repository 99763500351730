import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.vCardClass, attrs: { flat: true } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _vm.title
                ? _c(
                    VCardTitle,
                    { staticClass: "H4-Secondary-Center px-4 pt-4" },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm.description
                ? _c(
                    VCardText,
                    {
                      staticClass:
                        "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.description) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCardText,
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "", md8: "" } },
                [
                  _c(VTextField, {
                    staticClass: "full-underline",
                    attrs: {
                      label: "Search Sites",
                      "prepend-inner-icon": "mdi-magnify",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VLayout,
                { staticClass: "mt-4", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "", md6: "" } },
                    [
                      _c("p", { staticClass: "H6-Secondary-Left" }, [
                        _vm._v("All Sites")
                      ]),
                      _c(
                        VList,
                        {
                          staticClass: "list-panel no-padding-top",
                          class: {
                            "mr-2": _vm.$vuetify.breakpoint.mdAndUp,
                            "mb-4": _vm.$vuetify.breakpoint.smAndDown
                          }
                        },
                        [
                          _c(
                            VListTile,
                            { staticClass: "first-row-action" },
                            [
                              _c(
                                VListTileAction,
                                [
                                  _c(VCheckbox, {
                                    attrs: {
                                      disabled: _vm.isDisabled,
                                      value: _vm.isSelectAll
                                    },
                                    on: { change: _vm.selectAll }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VListTileContent,
                                [
                                  _c(VListTileTitle, [
                                    _vm._v("Select All")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.filterBy(
                              _vm.availableSites,
                              _vm.search,
                              "name"
                            ),
                            function(site) {
                              return _c(
                                VListTile,
                                { key: site.id },
                                [
                                  _c(
                                    VListTileAction,
                                    [
                                      _c(VCheckbox, {
                                        attrs: {
                                          value: _vm.isChecked[site.id],
                                          label: site.name
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.addSite(site)
                                          }
                                        },
                                        model: {
                                          value: _vm.isChecked[site.id],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.isChecked,
                                              site.id,
                                              $$v
                                            )
                                          },
                                          expression: "isChecked[site.id]"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "", md6: "" } },
                    [
                      _c("p", { staticClass: "H6-Secondary-Left" }, [
                        _vm._v(
                          "Sites Selected (" +
                            _vm._s(_vm.selectedSitesProxy.length) +
                            ")"
                        )
                      ]),
                      _c(
                        VList,
                        { staticClass: "list-panel no-padding-top" },
                        [
                          _c(
                            VListTile,
                            { staticClass: "first-row-action" },
                            [
                              _c(
                                VListTileAction,
                                [
                                  _c(
                                    VIcon,
                                    {
                                      attrs: { disabled: _vm.isDisabled },
                                      on: { click: _vm.removeAll }
                                    },
                                    [_vm._v("mdi-close-circle")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                VListTileContent,
                                [
                                  _c(VListTileTitle, [
                                    _vm._v("Remove All")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.filterBy(
                              _vm.selectedSitesProxy,
                              _vm.search,
                              "name"
                            ),
                            function(site) {
                              return _c(
                                VListTile,
                                { key: site.id },
                                [
                                  _c(
                                    VListTileAction,
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.removeSite(site)
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-close-circle")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VListTileContent,
                                    [
                                      _c(VListTileTitle, [
                                        _vm._v(_vm._s(site.name))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }