<template>
  <v-container grid-list-md>
    <v-form ref="form" lazy-validation class="settings" v-model="valid">
      <v-layout class="wrap">
        <v-flex xs12>
          <view-title />
        </v-flex>
        <v-flex xs12>
          <promo-name :promo.sync="promo"> </promo-name>
        </v-flex>
        <v-flex xs12>
          <promo-location
            :multigroup.sync="multigroupProxy"
            :company.sync="companyProxy"
            :locationGroups.sync="promo.location_group_ids"
            heading="Promotion Locations"
            subHeading="Promotions will be active across all"
            subHeading1="at the selected sites"
            selectBrandLabel="Select Brand (Global Menu)"
            :showLocationFilter="true"
            :confirmMultigroupChange="true"
            :confirmCompanyChange="true"
            :multigroupsMap="multigroupsMap"
            :selectSitesSubheading="'Choose sites to enable promotions'"
          ></promo-location>
        </v-flex>
        <v-flex xs12>
          <promo-schedule
            label="Promotion"
            :schedule.sync="promoCalendar.schedule"
            :validateAllDayTime="true"
          />
        </v-flex>
        <v-flex xs12 style="padding-bottom:50px">
          <promo-details
            :promo.sync="promo"
            :companyValue="companyValue"
            :promoTypeValues="promoTypeValues"
            :isNewPromo="isNewPromo"
          >
          </promo-details>
        </v-flex>
        <v-flex xs12>
          <save-footer
            v-show="isModified"
            :cancelAction="cancel"
            :saveLabel="isNewPromo ? 'Create Promotion' : 'Save Changes'"
            :saveAction="save"
            :showCreateIcon="true"
          />
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import DateTime from 'luxon/src/datetime.js';
import { mapActions, mapGetters } from 'vuex';
import promoLocation from '@/components/siteSelection';
import promoName from './components/promoName';
import promoSchedule from './components/promoSchedule';
import promoDetails from './components/promoDetails';

export default {
  components: {
    promoName,
    promoLocation,
    promoSchedule,
    promoDetails,
  },
  data: () => ({
    valid: true,
    promo: {
      label: { en: '' },
      description: { en: '' },
      multigroup: '',
      company: '',
      location_group_ids: [],
      type: '',
      badge: { en: '', fr: '' },
      status: 'scheduled',
      items: {
        main: [],
        additional: [],
      },
      calendar: {
        events: {
          data: { calendar: 'hours' },
          schedule: {
            startDate: '',
            endDate: '',
            startEndTimes: [
              {
                startTime: '',
                endTime: '',
              },
            ],
            days: [],
          },
        },
      },
    },
    promoCalendar: {
      schedule: {
        startDate: '',
        endDate: '',
        startEndTimes: [
          {
            startTime: '',
            endTime: '',
          },
        ],
        days: [],
      },
    },
    backupPromo: null,
    backupPromoCalendar: null,
    multigroupsMap: {},
    companyValue: {},
    promoTypeValues: {
      bogo: 'bogo',
      bundle: 'bundle',
      lto: 'lto',
    },
  }),
  methods: {
    ...mapActions('sites', ['getLocationMultigroup']),
    ...mapActions('promotions', ['fetchPromotion', 'putPromotion', 'postPromotion']),
    ...mapActions('sectors', ['fetchCompany']),
    cancel() {
      // restore backups
      this.$set(this, 'promo', cloneDeep(this.backupPromo));
      this.$set(this, 'promoCalendar', cloneDeep(this.backupPromoCalendar));

      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    async save() {
      const isValidForm = this.$refs.form.validate();
      if (!this.valid || !isValidForm) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      if (!this.promo.location_group_ids.length) {
        this.$toast('Sites for promo are not selected.');
        return;
      }
      if (!this.promo.items.main.length && this.promo.type === this.promoTypeValues.lto) {
        this.$toast('At least one Item is required for Limited Time Offer promo.');
        return;
      }
      if (!this.promo.items.main.length && this.promo.type === this.promoTypeValues.bundle) {
        this.$toast('At least one main Item is required for Bundle promo.');
        return;
      }
      if (
        (!this.promo.items.main.length || !this.promo.items.additional.length) &&
        this.promo.type === this.promoTypeValues.bogo
      ) {
        this.$toast('At least one main Item and one Free Item is required for BOGO promo.');
        return;
      }
      if (
        this.promoCalendar &&
        this.promoCalendar.schedule &&
        this.promoCalendar.schedule.startEndTimes &&
        this.promoCalendar.schedule.startEndTimes.length > 0
      ) {
        const invalid24DayPromo = this.promoCalendar.schedule.startEndTimes.find(
          (time) => time.startTime === time.endTime && !time.is24Hours,
        );
        if (invalid24DayPromo) {
          this.$toast(
            'You cannot have same start and end time for promo, unless it is set to 24 hours.',
          );
          return;
        }
      }
      const locationGroups = this.promo.location_group_ids.map((m) => m.id);
      this.promo.location_group_ids = [...locationGroups];
      let error;

      try {
        this.promo.calendar = this.getScheduleCalendar();
        if (this.isNewPromo) {
          await this.postPromotion({ promo: this.promo });
        } else {
          await this.putPromotion({ promo: this.promo });
        }
      } catch (err) {
        error = err;
      }
      if (error) {
        console.error(error);
        this.$toast.error(`Unable to save promotion.`);
        return;
      }
      this.$toast('Saved successfully');
      this.$router.push({
        name: 'promo',
      });
    },
    getScheduleCalendar() {
      const dayOfWeek = this.promoCalendar.schedule.days.map((d) => this.getDayOfWeekAsInteger(d));
      const events = this.promoCalendar.schedule.startEndTimes.map((time) => {
        return {
          data: {
            calendar: 'hours',
          },
          schedule: {
            start: this.getPromoDateAndTimeToSave(
              this.promoCalendar.schedule.startDate,
              time.startTime,
              true,
            ),
            end: this.getPromoDateAndTimeToSave(
              this.promoCalendar.schedule.endDate,
              time.endTime,
              false,
            ),
            times: [time.startTime],
            duration: time.is24Hours ? 1440 : this.getDuration(time.startTime, time.endTime),
            durationUnit: 'minutes',
            dayOfWeek,
          },
        };
      });
      const timezones = this.promo.location_group_ids
        .map((location) => {
          const locationTimezone = this.getLocationTimeZone(location);
          return {
            locationId: location,
            timezone: locationTimezone,
          };
        })
        .reduce((obj, item) => ({ ...obj, ...{ [item.locationId]: item.timezone } }), {});

      return {
        timezones,
        events,
      };
    },
    getLocationTimeZone(locationId) {
      try {
        const selectedMultigroup = this.multigroupsMap[this.multigroupProxy.id];
        // we will be getting very first brand's timezone from a location as all the brands should have the same timezone.
        return selectedMultigroup.groups.find((l) => l.id === locationId).locations[0].brands[0]
          .timezone;
      } catch (error) {
        console.error(error);
        throw new Error('There is an error while getting timezone.');
      }
    },
    getPromoDateAndTimeToSave(date, time, isStartTime) {
      const DateTimeToSave = new Date(Date.parse(`${date} ${time}`));
      return {
        year: DateTimeToSave.getFullYear(),
        month: DateTimeToSave.getMonth() + 1,
        day: DateTimeToSave.getDate(),
        hour: isStartTime ? 0 : 23,
        minute: isStartTime ? 0 : 59,
      };
    },
    getDuration(start, end) {
      if (!start || !end) return 0;
      const today = DateTime.local();
      const startTimes = start.split(':');
      const endTime = end.split(':');

      let startDateTime = DateTime.local();
      let closeDateTime = DateTime.local();

      if (startTimes.length === 1) {
        startDateTime = today.set({ hour: startTimes[0], minutes: '0' });
      } else {
        startDateTime = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      if (endTime.length === 1) {
        closeDateTime = today.set({ hour: endTime[0], minutes: '0' });
      } else {
        closeDateTime = today.set({ hour: endTime[0], minute: endTime[1] });
      }
      const duration = closeDateTime.diff(startDateTime, 'minutes');
      return duration.values.minutes;
    },
    getDayOfWeekAsInteger(day) {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(
        day,
      );
    },
    getDayOfWeekFromInteger(day) {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
    },
    getCalendarFromEvents(events) {
      if (!events || !events.length === 0) return {};

      const { startDate, endDate } = this.getStartAndEndDatesFromEventSchdule(
        events[0] && events[0].schedule,
      );
      const daysOfWeekAsString = events[0].schedule.dayOfWeek.map((d) =>
        this.getDayOfWeekFromInteger(d),
      );
      return {
        schedule: {
          startDate,
          endDate,
          startEndTimes: [...this.getStartAndEndTimesFromEvents(events)],
          days: [...daysOfWeekAsString],
        },
      };
    },
    getStartAndEndDatesFromEventSchdule(schedule) {
      if (!schedule.start || !schedule.end) return '';

      const startDate = DateTime.local().set({
        year: schedule.start.year,
        month: schedule.start.month,
        day: schedule.start.day,
      });
      const endDate = DateTime.local().set({
        year: schedule.end.year,
        month: schedule.end.month,
        day: schedule.end.day,
      });

      return { startDate: startDate.toISODate(), endDate: endDate.toISODate() };
    },
    getStartAndEndTimesFromEvents(events) {
      if (!events || events.length === 0 || !events[0].schedule.start || !events[0].schedule.end)
        return '';

      const timesToReturn = events.map((event) => {
        const today = DateTime.local();
        const startTime = event.schedule.times[0];
        const startTimes = startTime.split(':');

        let startDate;

        if (startTimes.length === 1) {
          startDate = today.set({ hour: startTimes[0] });
        } else {
          startDate = today.set({ hour: startTimes[0], minute: startTimes[1] });
        }

        const endDate = startDate.plus({ minutes: event.schedule.duration });

        return {
          startTime: startDate.toFormat('HH:mm'),
          endTime: endDate.toFormat('HH:mm'),
        };
      });
      return timesToReturn;
    },
    async check24HoursPromo() {
      if (this.promoCalendar && this.promoCalendar.schedule) {
        this.promoCalendar.schedule.startEndTimes.forEach((time) => {
          if (time.startTime === time.endTime) {
            time.is24Hours = true;
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters('promotions', ['getPromotionCompanies']),
    promo_id() {
      return this.$route.params.promo_id;
    },
    multigroupProxy: {
      get() {
        if (this.promo.multigroup !== '') return { id: this.promo.multigroup };
        return {};
      },
      set(value) {
        this.$set(this.promo, 'multigroup', value.id);
      },
    },
    companyProxy: {
      get() {
        if (this.promo.company && this.promo.company !== '')
          return {
            id: (this.promo.company && this.promo.company.id) || this.promo.company,
            name: (this.companyValue && this.companyValue.name) || '',
          };
        return {};
      },
      set(value) {
        if (isEqual(this.promo.company, value && value.id)) return;
        this.$set(this.promo, 'company', value.id);
        this.$set(this.promo, 'items', { main: [], additional: [] });
        this.$set(this, 'companyValue', value);
      },
    },
    isNewPromo() {
      return !this.promo_id;
    },
    isModified() {
      return (
        !isEqual(this.promo, this.backupPromo) ||
        !isEqual(this.promoCalendar, this.backupPromoCalendar)
      );
    },
    today() {
      return DateTime.local().toISODate();
    },
  },
  async mounted() {
    this.$store.commit('adminPanel/setLoading', true);
    this.loading = true;
    const { promo_id } = this.$route.params;

    if (this.isNewPromo) {
      this.$set(this, 'backupPromo', cloneDeep(this.promo));
      this.$set(this, 'backupPromoCalendar', cloneDeep(this.promoCalendar));
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Create New Promotion',
        to: { name: 'promo-new' },
      });
    } else {
      const promo = await this.fetchPromotion(promo_id);
      const promoCalendar = this.getCalendarFromEvents(promo.calendar.events);
      promo.location_group_ids = promo.location_group_ids.map((group) => {
        return { id: group, name: '' };
      });
      this.promo = cloneDeep(promo);
      this.promoCalendar = cloneDeep(promoCalendar);
      await this.check24HoursPromo();
      if (promo.company) {
        const companyDetails = this.getPromotionCompanies(promo.company);
        if (companyDetails.length > 0)
          this.companyValue = { id: promo.company, name: companyDetails[0].name || '' };
      }
      this.$set(this, 'backupPromo', cloneDeep(this.promo));
      this.$set(this, 'backupPromoCalendar', cloneDeep(this.promoCalendar));
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Edit Promotion',
        to: { name: 'promo-edit', params: { promo_id } },
      });
    }
    this.$store.commit('adminPanel/setLoading', false);
    this.loading = false;
  },
};
</script>
