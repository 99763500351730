var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-loader",
    {
      ref: "content",
      attrs: {
        width: _vm.parentWidth,
        height: _vm.parentHeight,
        speed: 2,
        primaryColor: "#f3f3f3",
        secondaryColor: "#ecebeb"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: _vm.margin[1],
          y: 0,
          rx: "3",
          ry: "3",
          width: _vm.blockWidth,
          height: _vm.height
        }
      }),
      _vm._l(_vm.rows - 1, function(index) {
        return _c("rect", {
          key: index,
          attrs: {
            x: _vm.margin[1],
            y: index * (_vm.height + _vm.margin[0]),
            rx: "3",
            ry: "3",
            width: _vm.blockWidth,
            height: _vm.height
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }