<template>
  <v-layout row wrap>
    <v-card class="w100">
      <v-card-title primary-title>
        <div class="H5-Primary-Left">Promotion Details</div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout row wrap>
          <v-flex xs4>
            <div class="H6-Secondary-Left mb-3">
              Promotion Type
            </div>
            <v-select
              v-model="promoType"
              :items="promotionTypes"
              class="dropdown-always-active-label"
              item-text="name"
              item-value="id"
              label="Promotion Type"
              outline
              :rules="promotionTypeRules"
              :disabled="!isNewPromo"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <div class="H6-Secondary-Left mb-3">
              Promotion Text
            </div>
            <v-text-field
              label="English"
              type="string"
              v-model="badgeEnglish"
              outline
              :rules="badgeRules"
              placeholder="English"
            />
          </v-flex>
          <v-flex xs4>
            <div class="H6-Secondary-Left mb-3"><br /></div>
            <v-text-field
              label="French"
              placeholder="French"
              type="string"
              v-model="badgeFrench"
              outline
              :rules="badgeRules"
          /></v-flex>
        </v-layout>
        <v-layout row wrap v-if="promoType === this.promoTypeValues.bundle">
          <v-flex xs12>
            <div class="H6-Secondary-Left mb-3">
              Bundle Summary
            </div>
            <v-layout row wrap v-if="promoType === this.promoTypeValues.bundle">
              <v-flex xs4>
                <div>
                  Bundle Price Before Discounts
                </div>
                <v-text-field
                  type="number"
                  :value="bundlePriceRegular"
                  placeholder="0"
                  :disabled="true"
                  prepend-inner-icon="mdi-currency-usd"
                  style="margin-top:-10px"
                />
              </v-flex>
              <v-flex xs4>
                <div>
                  Bundle Price After Discounts
                </div>
                <v-text-field
                  type="number"
                  :value="bundlePriceDiscounted"
                  placeholder="0"
                  :disabled="true"
                  prepend-inner-icon="mdi-currency-usd"
                  style="margin-top:-10px"
                />
              </v-flex>
              <v-flex xs4>
                <div>
                  Total Discount Given
                </div>
                <v-text-field
                  type="number"
                  :value="bundleDiscountGiven"
                  placeholder="0"
                  :disabled="true"
                  prepend-inner-icon="mdi-currency-usd"
                  style="margin-top:-10px"
                />
              </v-flex>
            </v-layout> </v-flex
        ></v-layout>

        <v-layout row wrap>
          <v-flex xs10 v-if="showAddMainItemButton">
            <div class="H6-Secondary-Left mb-3">
              {{ mainItemLabel }}
            </div>
            <div style="margin-top:-15px; margin-bottom:30px">
              {{ mainItemDescLabel }}
            </div>
          </v-flex>
          <v-flex xs12>
            <div v-if="showMainItems">
              <promo-details-item
                v-for="(promoItem, index) in promoProxy.items.main"
                :key="index"
                :selectedItem.sync="promoItem"
                :promoType="promoType"
                :promoTypeValues="promoTypeValues"
                :itemSelectionTypeValue="itemSelectionType.main"
                :itemSelectionType="itemSelectionType"
                :company="companyProxy"
                @selectedItem:delete="removeItem(index, itemSelectionType.main)"
                @selectedItem:edit="editPromoItem(index, itemSelectionType.main)"
              >
              </promo-details-item>
            </div>
            <div v-if="showAddMainItemButton">
              <menu-item-select-dialog
                :company="companyProxy"
                :items="promoProxy.items.main"
                :limit="itemSelectionLimits"
                @update:items="processItem(itemSelectionType.main, $event)"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" :disabled="!companyProxy.id">
                    <v-icon>mdi-plus</v-icon>Select {{ mainItemLabel }}
                  </v-btn>
                </template>
              </menu-item-select-dialog>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="promoType !== this.promoTypeValues.lto" style="padding-top:20px">
          <v-flex xs12>
            <div class="H6-Secondary-Left mb-3">
              {{ freeItemLabel }}
            </div>
            <div style="margin-top:-15px; margin-bottom:30px">
              {{ freeItemDescLabel }}
            </div>
          </v-flex>
          <v-flex xs12>
            <div v-if="promoProxy.items.additional.length > 0">
              <promo-details-item
                v-for="(promoItem, index) in promoProxy.items.additional"
                :key="index"
                :selectedItem.sync="promoItem"
                :promoType="promoType"
                :promoTypeValues="promoTypeValues"
                :itemSelectionTypeValue="itemSelectionType.free"
                :itemSelectionType="itemSelectionType"
                :company="companyProxy"
                @selectedItem:delete="removeItem(index, itemSelectionType.free)"
                @selectedItem:edit="editPromoItem(index, itemSelectionType.free)"
              ></promo-details-item>
            </div>
            <div v-if="showAddAdditionalItem">
              <menu-item-select-dialog
                :company="companyProxy"
                :items="promoProxy.items.additional"
                @update:items="processItem(itemSelectionType.free, $event)"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="primary" :disabled="!companyProxy.id">
                    <v-icon>mdi-plus</v-icon>Select {{ freeItemLabel }}
                  </v-btn>
                </template>
              </menu-item-select-dialog>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </v-layout>
</template>

<script>
import add from 'lodash/add';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import rules from '@/rules';
import MenuItemSelectDialog from '@/components/MenuItemSelect/Dialog';
import promoDetailsItem from './promoDetailsItem';

export default {
  components: {
    MenuItemSelectDialog,
    promoDetailsItem,
  },
  props: ['promo', 'companyValue', 'promoTypeValues', 'isNewPromo'],
  data: () => ({
    promotionTypeRules: [rules.required('Promotion Type is Required')],
    discountTypeRules: [rules.required('Discount Type is Required')],
    badgeRules: [rules.required('Promotion Text is Required')],
    promotionTypes: [
      { name: 'BOGO', id: 'bogo' },
      { name: 'Bundle', id: 'bundle' },
      { name: 'Limited Time Offer', id: 'lto' },
    ],

    showItemDialog: false,
    itemSelectionType: { main: 1, free: 2 },
    itemSelectionTypeValue: null,
    itemSelectionDialogHeading: '',
    isAddItem: true,
    selectedItemIndex: null,
  }),
  computed: {
    promoProxy: {
      get() {
        return this.promo;
      },
      set(value) {
        this.$emit('update:promo', value);
      },
    },
    companyProxy() {
      if (this.promoProxy.company !== '')
        return { id: this.promoProxy.company, name: this.companyValue.name };
      return { id: undefined, name: '' };
    },
    mainItemLabel() {
      if (this.promoType === this.promoTypeValues.lto) return 'Items';
      return 'Main Item';
    },
    mainItemDescLabel() {
      if (this.promoType === this.promoTypeValues.lto) return 'Select the item in your promotion';
      return 'Select the main item in your promotion';
    },
    freeItemLabel() {
      if (this.promoType === this.promoTypeValues.bogo) return 'Free Item';
      if (this.promoType === this.promoTypeValues.bundle) return 'Bundle Item';
      return '';
    },
    freeItemDescLabel() {
      if (this.promoType === this.promoTypeValues.bogo)
        return 'Select the free item in your promotion';
      if (this.promoType === this.promoTypeValues.bundle)
        return 'Select the item in your promotion bundle';
      return '';
    },
    showMainItems() {
      return this.promoProxy.items.main.length !== 0;
    },
    showAddMainItemButton() {
      if (this.promoType === this.promoTypeValues.bundle && this.promoProxy.items.main.length < 2)
        return true;
      if (this.promoType === this.promoTypeValues.bogo && this.promoProxy.items.main.length === 0)
        return true;
      return this.promoType === this.promoTypeValues.lto;
    },
    promoType: {
      get() {
        return this.promo.type;
      },
      async set(value) {
        if (
          this.promoProxy.items.main.length === 0 &&
          this.promoProxy.items.additional.length === 0
        ) {
          this.promoProxy = { ...this.promoProxy, type: value };
          return;
        }

        const input = await this.$confirm({
          title: 'Promotion Type',
          message:
            'Changing promotion type will reset all your selected item(s). Do you want to continue?',
          buttonTrueText: 'OK',
          buttonFalseText: 'CANCEL',
        });

        if (!input) {
          const oldValue = this.promoType;
          this.$set(this.promoProxy, 'type', value);
          this.$nextTick(() => {
            this.$set(this.promoProxy, 'type', oldValue);
          });
          return;
        }
        this.promoProxy = { ...this.promoProxy, type: value, items: { main: [], additional: [] } };
      },
    },
    badgeEnglish: {
      get() {
        return this.promo.badge.en;
      },
      set(value) {
        this.$set(this.promoProxy, 'badge', { en: value, fr: this.badgeFrench });
      },
    },
    badgeFrench: {
      get() {
        return this.promo.badge.fr;
      },
      set(value) {
        this.$set(this.promoProxy, 'badge', { en: this.badgeEnglish, fr: value });
      },
    },

    itemSelectionLimits() {
      if (this.promoType === this.promoTypeValues.bundle) return 2;
      if (this.promoType === this.promoTypeValues.lto) return undefined;
      return 1;
    },
    showAddAdditionalItem() {
      if (this.promoType === this.promoTypeValues.bundle) return true;
      if (this.promoType === this.promoTypeValues.bogo && this.promo.items.additional.length === 0)
        return true;
      return false;
    },
    bundlePriceRegular() {
      const mainItemTotal = this.promoProxy.items.main.reduce(
        (prev, curr) => add(prev, curr.price.amount * curr.quantity),
        0,
      );
      const additionalItemTotal = this.promoProxy.items.additional.reduce(
        (prev, curr) => add(prev, curr.price.amount * curr.quantity),
        0,
      );
      return add(parseFloat(mainItemTotal), parseFloat(additionalItemTotal)).toFixed(2);
    },
    bundlePriceDiscounted() {
      const mainItemDiscountedTotal = this.promoProxy.items.main.reduce(
        (prev, curr) => add(prev, this.getDiscountedTotals(curr)),
        0,
      );
      const additionalItemDiscountedTotal = this.promoProxy.items.additional.reduce(
        (prev, curr) => add(prev, this.getDiscountedTotals(curr)),
        0,
      );
      return add(
        parseFloat(mainItemDiscountedTotal),
        parseFloat(additionalItemDiscountedTotal),
      ).toFixed(2);
    },
    bundleDiscountGiven() {
      return (parseFloat(this.bundlePriceRegular) - parseFloat(this.bundlePriceDiscounted)).toFixed(
        2,
      );
    },
  },
  methods: {
    processItem(itemType, items) {
      const newItems = items.map((item) => {
        let existingItem;
        if (itemType === this.itemSelectionType.main) {
          existingItem = this.promoProxy.items.main.find((i) => i.id === item.id);
        }
        if (itemType === this.itemSelectionType.free) {
          existingItem = this.promoProxy.items.additional.find((i) => i.id === item.id);
        }

        return {
          id: item.id,
          quantity: 1,
          price: { amount: item.price.amount },
          label: { en: item.label.en },
          discount: !existingItem
            ? { amount_off: null, percent_off: null }
            : { ...existingItem.discount },
        };
      });

      if (itemType === this.itemSelectionType.main) {
        this.$set(this.promoProxy.items, 'main', cloneDeep(newItems));
      }
      if (itemType === this.itemSelectionType.free) {
        this.$set(this.promoProxy.items, 'additional', cloneDeep(newItems));
      }
    },

    async removeItem(index, itemSelectionType) {
      const confirmDialogTitle =
        itemSelectionType === this.itemSelectionType.main ? 'Main' : 'Bundle';
      const input = await this.$confirm({
        title: `Remove ${confirmDialogTitle} Item`,
        message: `Are you sure you want to remove this item?`,
        buttonTrueText: 'REMOVE',
        buttonFalseText: 'CANCEL',
      });
      if (!input) return;
      if (itemSelectionType === this.itemSelectionType.main)
        this.promoProxy.items.main.splice(index, 1);
      if (itemSelectionType === this.itemSelectionType.free)
        this.promoProxy.items.additional.splice(index, 1);
    },
    addPromoItem(itemSelectionType) {
      this.isAddItem = true;
      this.selectedItemIndex = null;

      if (itemSelectionType === this.itemSelectionType.main)
        this.itemSelectionDialogHeading = 'Select Main Item';
      if (itemSelectionType === this.itemSelectionType.free)
        this.itemSelectionDialogHeading = 'Select Bundle Item';
      this.itemSelectionTypeValue = itemSelectionType;
      this.showItemDialog = true;
    },
    editPromoItem(index, itemSelectionType) {
      if (itemSelectionType === this.itemSelectionType.main)
        this.itemSelectionDialogHeading = 'Select Main Item';
      if (itemSelectionType === this.itemSelectionType.free)
        this.itemSelectionDialogHeading = 'Select Bundle Item';

      this.isAddItem = false;
      this.selectedItemIndex = index;
      this.itemSelectionTypeValue = itemSelectionType;
      this.showItemDialog = true;
    },
    getDiscountedTotals(item) {
      let itemTotal = item.price.amount * item.quantity;
      if (item.discount && !isNil(item.discount.amount_off)) itemTotal -= item.discount.amount_off;
      if (item.discount && !isNil(item.discount.percent_off))
        itemTotal -= (item.discount.percent_off / 100) * (item.price.amount * item.quantity);
      return parseFloat(itemTotal, 2);
    },
  },
};
</script>
