import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    _vm._b(
      {
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _vm._t(
                    "activator",
                    [
                      _c(
                        VBtn,
                        _vm._g(
                          _vm._b({}, "v-btn", _vm.combinedButtonProps, false),
                          on
                        ),
                        [
                          _c(VIcon, [_vm._v("mdi-plus")]),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.dialogBtnTxt
                                  ? _vm.dialogBtnTxt
                                  : "Add Item" + (_vm.limit == 1 ? "" : "(s)")
                              ) +
                              "\n      "
                          )
                        ],
                        1
                      )
                    ],
                    null,
                    { on: on }
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      },
      "v-dialog",
      _vm.combinedDialogProps,
      false
    ),
    [
      _c("menu-item-select-card", {
        attrs: {
          company: _vm.company,
          items: _vm.items,
          limit: _vm.limit,
          noActiveMenuText: _vm.noActiveMenuText,
          selectionTableProps: _vm.selectionTableProps
        },
        on: {
          "save-dialog": _vm.onSave,
          "cancel-dialog": function($event) {
            _vm.dialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }