import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(
        VCard,
        { staticClass: "w100" },
        [
          _c(VCardTitle, { attrs: { "primary-title": "" } }, [
            _c("div", { staticClass: "H5-Primary-Left" }, [
              _vm._v("Promotion Details")
            ])
          ]),
          _c(
            "div",
            { staticClass: "v-card-content" },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs4: "" } },
                    [
                      _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                        _vm._v("\n            Promotion Type\n          ")
                      ]),
                      _c(VSelect, {
                        staticClass: "dropdown-always-active-label",
                        attrs: {
                          items: _vm.promotionTypes,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Promotion Type",
                          outline: "",
                          rules: _vm.promotionTypeRules,
                          disabled: !_vm.isNewPromo
                        },
                        model: {
                          value: _vm.promoType,
                          callback: function($$v) {
                            _vm.promoType = $$v
                          },
                          expression: "promoType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs4: "" } },
                    [
                      _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                        _vm._v("\n            Promotion Text\n          ")
                      ]),
                      _c(VTextField, {
                        attrs: {
                          label: "English",
                          type: "string",
                          outline: "",
                          rules: _vm.badgeRules,
                          placeholder: "English"
                        },
                        model: {
                          value: _vm.badgeEnglish,
                          callback: function($$v) {
                            _vm.badgeEnglish = $$v
                          },
                          expression: "badgeEnglish"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs4: "" } },
                    [
                      _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                        _c("br")
                      ]),
                      _c(VTextField, {
                        attrs: {
                          label: "French",
                          placeholder: "French",
                          type: "string",
                          outline: "",
                          rules: _vm.badgeRules
                        },
                        model: {
                          value: _vm.badgeFrench,
                          callback: function($$v) {
                            _vm.badgeFrench = $$v
                          },
                          expression: "badgeFrench"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.promoType === this.promoTypeValues.bundle
                ? _c(
                    VLayout,
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                            _vm._v("\n            Bundle Summary\n          ")
                          ]),
                          _vm.promoType === this.promoTypeValues.bundle
                            ? _c(
                                VLayout,
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                Bundle Price Before Discounts\n              "
                                        )
                                      ]),
                                      _c(VTextField, {
                                        staticStyle: { "margin-top": "-10px" },
                                        attrs: {
                                          type: "number",
                                          value: _vm.bundlePriceRegular,
                                          placeholder: "0",
                                          disabled: true,
                                          "prepend-inner-icon":
                                            "mdi-currency-usd"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                Bundle Price After Discounts\n              "
                                        )
                                      ]),
                                      _c(VTextField, {
                                        staticStyle: { "margin-top": "-10px" },
                                        attrs: {
                                          type: "number",
                                          value: _vm.bundlePriceDiscounted,
                                          placeholder: "0",
                                          disabled: true,
                                          "prepend-inner-icon":
                                            "mdi-currency-usd"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          "\n                Total Discount Given\n              "
                                        )
                                      ]),
                                      _c(VTextField, {
                                        staticStyle: { "margin-top": "-10px" },
                                        attrs: {
                                          type: "number",
                                          value: _vm.bundleDiscountGiven,
                                          placeholder: "0",
                                          disabled: true,
                                          "prepend-inner-icon":
                                            "mdi-currency-usd"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _vm.showAddMainItemButton
                    ? _c(VFlex, { attrs: { xs10: "" } }, [
                        _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.mainItemLabel) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "-15px",
                              "margin-bottom": "30px"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.mainItemDescLabel) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _vm.showMainItems
                      ? _c(
                          "div",
                          _vm._l(_vm.promoProxy.items.main, function(
                            promoItem,
                            index
                          ) {
                            return _c("promo-details-item", {
                              key: index,
                              attrs: {
                                selectedItem: promoItem,
                                promoType: _vm.promoType,
                                promoTypeValues: _vm.promoTypeValues,
                                itemSelectionTypeValue:
                                  _vm.itemSelectionType.main,
                                itemSelectionType: _vm.itemSelectionType,
                                company: _vm.companyProxy
                              },
                              on: {
                                "update:selectedItem": function($event) {
                                  promoItem = $event
                                },
                                "update:selected-item": function($event) {
                                  promoItem = $event
                                },
                                "selectedItem:delete": function($event) {
                                  return _vm.removeItem(
                                    index,
                                    _vm.itemSelectionType.main
                                  )
                                },
                                "selectedItem:edit": function($event) {
                                  return _vm.editPromoItem(
                                    index,
                                    _vm.itemSelectionType.main
                                  )
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.showAddMainItemButton
                      ? _c(
                          "div",
                          [
                            _c("menu-item-select-dialog", {
                              attrs: {
                                company: _vm.companyProxy,
                                items: _vm.promoProxy.items.main,
                                limit: _vm.itemSelectionLimits
                              },
                              on: {
                                "update:items": function($event) {
                                  return _vm.processItem(
                                    _vm.itemSelectionType.main,
                                    $event
                                  )
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled: !_vm.companyProxy.id
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(VIcon, [_vm._v("mdi-plus")]),
                                            _vm._v(
                                              "Select " +
                                                _vm._s(_vm.mainItemLabel) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3932288035
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm.promoType !== this.promoTypeValues.lto
                ? _c(
                    VLayout,
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { row: "", wrap: "" }
                    },
                    [
                      _c(VFlex, { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "H6-Secondary-Left mb-3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.freeItemLabel) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "-15px",
                              "margin-bottom": "30px"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.freeItemDescLabel) +
                                "\n          "
                            )
                          ]
                        )
                      ]),
                      _c(VFlex, { attrs: { xs12: "" } }, [
                        _vm.promoProxy.items.additional.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.promoProxy.items.additional, function(
                                promoItem,
                                index
                              ) {
                                return _c("promo-details-item", {
                                  key: index,
                                  attrs: {
                                    selectedItem: promoItem,
                                    promoType: _vm.promoType,
                                    promoTypeValues: _vm.promoTypeValues,
                                    itemSelectionTypeValue:
                                      _vm.itemSelectionType.free,
                                    itemSelectionType: _vm.itemSelectionType,
                                    company: _vm.companyProxy
                                  },
                                  on: {
                                    "update:selectedItem": function($event) {
                                      promoItem = $event
                                    },
                                    "update:selected-item": function($event) {
                                      promoItem = $event
                                    },
                                    "selectedItem:delete": function($event) {
                                      return _vm.removeItem(
                                        index,
                                        _vm.itemSelectionType.free
                                      )
                                    },
                                    "selectedItem:edit": function($event) {
                                      return _vm.editPromoItem(
                                        index,
                                        _vm.itemSelectionType.free
                                      )
                                    }
                                  }
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _vm.showAddAdditionalItem
                          ? _c(
                              "div",
                              [
                                _c("menu-item-select-dialog", {
                                  attrs: {
                                    company: _vm.companyProxy,
                                    items: _vm.promoProxy.items.additional
                                  },
                                  on: {
                                    "update:items": function($event) {
                                      return _vm.processItem(
                                        _vm.itemSelectionType.free,
                                        $event
                                      )
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: !_vm.companyProxy
                                                      .id
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-plus")
                                                ]),
                                                _vm._v(
                                                  "Select " +
                                                    _vm._s(_vm.freeItemLabel) +
                                                    "\n                "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3967351740
                                  )
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }