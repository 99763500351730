import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pt-3" },
    [
      _c(
        VLayout,
        { attrs: { row: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs4: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "ml-3",
                        "H5-Primary-Left",
                        { "mb-3": !_vm.limit, "mb-0": _vm.limit }
                      ]
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.company.name) + "\n        "
                      )
                    ]
                  ),
                  _vm.limit
                    ? _c(VFlex, { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "ml-3 mb-3" }, [
                          _vm._v(
                            "Selected " +
                              _vm._s(_vm.selectedItems.length) +
                              " of " +
                              _vm._s(_vm.limit)
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "placeholder",
                {
                  attrs: { loading: _vm.loading },
                  scopedSlots: _vm._u([
                    {
                      key: "loading",
                      fn: function() {
                        return [_c("block-placeholder", { attrs: { rows: 3 } })]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  !_vm.menuLength
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-block pa-0 pl-3 mt-3 text-xs-center py-3 px-3"
                        },
                        [
                          _vm._v(
                            "\n          No Menus were found for this brand.\n        "
                          )
                        ]
                      )
                    : _c(
                        VExpansionPanel,
                        { staticClass: "panel" },
                        _vm._l(_vm.menus, function(menu) {
                          return _c(
                            VExpansionPanelContent,
                            {
                              key: menu.id,
                              attrs: { lazy: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "menu py-3 px-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.loadMenuGroups(
                                                  menu.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(menu.name) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "actions",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "menu py-3 px-3",
                                            on: {
                                              click: function($event) {
                                                return _vm.loadMenuGroups(
                                                  menu.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(VIcon, [
                                              _vm._v("$vuetify.icons.expand")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "placeholder",
                                {
                                  attrs: {
                                    loading: _vm.menus[menu.id].loading
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "loading",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "placeholder" },
                                              [
                                                _c("block-placeholder", {
                                                  attrs: { rows: 3, height: 30 }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  !_vm.groupLength(menu.id)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-block pa-0 pl-3 mt-3 text-xs-center py-3 px-3"
                                        },
                                        [
                                          _vm._v(
                                            "\n                No categories were found for this menu.\n              "
                                          )
                                        ]
                                      )
                                    : _c(
                                        VList,
                                        _vm._l(_vm.groups[menu.id], function(
                                          group
                                        ) {
                                          return _c(
                                            VListTile,
                                            {
                                              key: group.id,
                                              on: {
                                                click: function($event) {
                                                  return _vm.setActive(
                                                    menu.id,
                                                    group.id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                VListTileContent,
                                                {
                                                  staticClass: "panel-content"
                                                },
                                                [
                                                  _c(
                                                    VCheckbox,
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "ma-0 pa-0",
                                                        attrs: {
                                                          readonly: "",
                                                          ripple: false,
                                                          primary: "",
                                                          "hide-details": ""
                                                        }
                                                      },
                                                      "v-checkbox",
                                                      _vm.checkboxState(
                                                        menu.id,
                                                        group.id
                                                      ),
                                                      false
                                                    )
                                                  ),
                                                  _c(VListTileTitle, {
                                                    staticClass: "group ml-3",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        group.name
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VFlex,
            { staticClass: "table-container", attrs: { xs8: "" } },
            [
              _vm.active.group
                ? _c(
                    VCardTitle,
                    {
                      staticClass: "d-block py-3 pl-3",
                      attrs: { "primary-title": "" }
                    },
                    [
                      _c("h3", { staticClass: "headline mb-0" }, [
                        _vm._v(
                          _vm._s(
                            _vm.groups[_vm.active.menu][_vm.active.group].name
                          )
                        )
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.menus[_vm.active.menu].name))
                      ])
                    ]
                  )
                : _c(
                    VCardTitle,
                    {
                      staticClass: "d-block pa-0 pl-3 mt-3 text-xs-center",
                      attrs: { "primary-title": "" }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.noActiveMenuText) + "\n      "
                      )
                    ]
                  ),
              _vm.active.group
                ? _c("menu-item-table", {
                    attrs: {
                      items: _vm.tableItems,
                      limit: _vm.limit,
                      selectedItems: _vm.selectedItems,
                      selectionTableProps: _vm.selectionTableProps
                    },
                    on: {
                      "update:selectedItems": function($event) {
                        _vm.selectedItems = $event
                      },
                      "update:selected-items": function($event) {
                        _vm.selectedItems = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        VCardActions,
        { staticClass: "actions" },
        [
          _c(VSpacer),
          _c(
            VBtn,
            { attrs: { color: "blue", flat: "" }, on: { click: _vm.onCancel } },
            [_vm._v("Cancel")]
          ),
          _c(
            VBtn,
            {
              staticClass: "mr-2",
              attrs: { color: "primary" },
              on: { click: _vm.onSave }
            },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }