import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "" } },
    [
      _c(VFlex, { attrs: { xs4: "" } }, [
        _vm._v("\n    Item Name\n    "),
        _c(
          "div",
          { staticClass: "H6-Selected-On-Surface-Medium-Emphasis-Left" },
          [_vm._v("\n      " + _vm._s(_vm.selectedItem.label.en) + "\n    ")]
        )
      ]),
      _c(
        VFlex,
        { attrs: { xs6: "" } },
        [
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(VFlex, { attrs: { xs4: "" } }, [
                _vm.showQuantity
                  ? _c("span", [
                      _vm._v("\n          Quantity\n          "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "-20px" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              type: "number",
                              min: "1",
                              outline: "",
                              rules: _vm.quantityRules
                            },
                            model: {
                              value: _vm.quantity,
                              callback: function($$v) {
                                _vm.quantity = $$v
                              },
                              expression: "quantity"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c(VFlex, { attrs: { xs4: "" } }, [
                _vm.showBogoControl
                  ? _c("span", [
                      _vm._v("Discount Type\n          "),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "-20px" } },
                        [
                          _c(VSelect, {
                            staticClass: "dropdown-always-active-label",
                            attrs: {
                              items: _vm.discountTypes,
                              "item-text": "name",
                              "item-value": "id",
                              rules: _vm.discountTypeRules,
                              outline: ""
                            },
                            model: {
                              value: _vm.itemDiscountType,
                              callback: function($$v) {
                                _vm.itemDiscountType = $$v
                              },
                              expression: "itemDiscountType"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _c(VFlex, { attrs: { xs4: "" } }, [
                _vm.showBogoControl
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.discountValueLabel) +
                          "\n          "
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "-20px" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              type: "number",
                              disabled: _vm.discountValueDisabled,
                              rules: _vm.discountValueRules,
                              "prepend-inner-icon": _vm.discountValueIcon
                            },
                            model: {
                              value: _vm.itemDiscountValue,
                              callback: function($$v) {
                                _vm.itemDiscountValue = $$v
                              },
                              expression: "itemDiscountValue"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { row: "", wrap: "" } },
            [
              _c(VFlex, { attrs: { xs3: "" } }, [
                _vm._v("\n        Price Per Item\n        "),
                _c(
                  "div",
                  {
                    staticClass: "H6-Selected-On-Surface-Medium-Emphasis-Left"
                  },
                  [_vm._v("$ " + _vm._s(_vm.globalItemPrice))]
                )
              ]),
              _c(VFlex, { attrs: { xs3: "" } }, [
                _vm._v("\n        Total Item Price\n        "),
                _c(
                  "div",
                  {
                    staticClass: "H6-Selected-On-Surface-Medium-Emphasis-Left"
                  },
                  [_vm._v("$ " + _vm._s(_vm.totalItemPrice))]
                )
              ]),
              _vm.showBogoControl
                ? _c(VFlex, { attrs: { xs3: "" } }, [
                    _vm._v("\n        Discounted Price\n        "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "H6-Selected-On-Surface-Medium-Emphasis-Left"
                      },
                      [_vm._v("$ " + _vm._s(_vm.discountedItemPrice))]
                    )
                  ])
                : _vm._e(),
              _vm.showBogoControl
                ? _c(VFlex, { attrs: { xs3: "" } }, [
                    _vm._v("\n        Discount Given\n        "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "H6-Selected-On-Surface-Medium-Emphasis-Left"
                      },
                      [_vm._v("$ " + _vm._s(_vm.itemDiscountGiven))]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs1: "" } },
        [
          _c("menu-item-select-dialog", {
            attrs: { company: _vm.company, items: _vm.itemToEdit, limit: 1 },
            on: {
              "update:items": function($event) {
                return _vm.editItem($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      VTooltip,
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var vTooltipOn = ref.on
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass: "buttonActions",
                                        attrs: {
                                          flat: "",
                                          icon: "",
                                          color: "#0d73d8"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit(
                                              "selectedItem:edit"
                                            )
                                          }
                                        }
                                      },
                                      Object.assign({}, on, vTooltipOn)
                                    ),
                                    [
                                      _c(VIcon, [_vm._v("mdi-pencil")]),
                                      _vm._v(
                                        "\n              EDIT\n            "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Edit Item")])]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs1: "" } },
        [
          _c(
            VTooltip,
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        VBtn,
                        _vm._g(
                          {
                            staticClass: "buttonActions",
                            attrs: { flat: "", icon: "", color: "#0d73d8" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("selectedItem:delete")
                              }
                            }
                          },
                          on
                        ),
                        [
                          _c(VIcon, [_vm._v("mdi-delete")]),
                          _vm._v("\n          REMOVE\n        ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Remove Item")])]
          )
        ],
        1
      ),
      _c(VFlex, { attrs: { xs12: "" } }, [_c(VDivider)], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }