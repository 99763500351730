<template>
  <v-layout row wrap mt-4 mb-4>
    <v-card class="w100">
      <v-card-title primary-title>
        <div class="H5-Primary-Left display-flex w100 justify-space-between">
          Promotion Schedule
        </div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">
                  Select Dates
                </div>
              </v-flex>
              <v-flex xs12>
                <v-layout class="wrap">
                  <v-flex xs3 mr-3>
                    <date-picker
                      :text-field-props="{
                        'hide-details': false,
                        label: 'Start Date',
                        rules: startDateRules,
                      }"
                      :picker-props="{ min: today }"
                      :value="schedule.startDate"
                      @update-date="onPropChange('startDate', $event)"
                    ></date-picker>
                  </v-flex>
                  <v-flex xs3>
                    <date-picker
                      :text-field-props="{
                        'hide-details': false,
                        disabled: false,
                        label: 'End Date',
                        rules: endDateRules,
                      }"
                      :menu-props="{ disabled: false }"
                      :picker-props="{ min: schedule.startDate || today }"
                      :value="schedule.endDate"
                      @update-date="onPropChange('endDate', $event)"
                    ></date-picker>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">
                  Select Times
                </div>
              </v-flex>
              <v-flex xs12>
                <template v-for="(slot, index) of schedule.startEndTimes">
                  <v-layout class="wrap" :key="index">
                    <v-flex xs2 mr-3>
                      <time-select
                        :label="'Start Time'"
                        v-bind:value.sync="slot.startTime"
                        :rules="startTimeRules"
                        style="width: 100%;"
                        autocomplete
                        :disabled="slot.is24Hours"
                      ></time-select>
                    </v-flex>
                    <v-flex xs2>
                      <time-select
                        :label="'End Time'"
                        v-bind:value.sync="slot.endTime"
                        :rules="endTimeRules(slot.endTime, index)"
                        style="width: 100%;"
                        :type="controlType"
                        autocomplete
                        :disabled="slot.is24Hours"
                      ></time-select>
                    </v-flex>
                    <v-flex xs2 class="CheckboxStylegroup" v-if="validateAllDayTime">
                      <v-checkbox
                        v-model="slot.is24Hours"
                        label="24 Hour Promo"
                        @change="allDayPromo(slot)"
                        color="#0d73d8"
                        class="CheckboxStyle"
                      />
                    </v-flex>
                    <v-flex xs3 v-if="showAddTimeslot">
                      <v-btn color="primary" flat @click="addRemoveTimeslot">
                        <v-icon left dark>mdi-plus</v-icon>
                        Add Time Slot
                      </v-btn>
                    </v-flex>
                    <v-flex xs3 v-else-if="schedule.startEndTimes.length === 2 && index === 1">
                      <v-btn color="primary" flat @click="addRemoveTimeslot">
                        <v-icon left dark>mdi-minus</v-icon>
                        Remove Time Slot
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </template>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs4>
                <div class="H6-Secondary-Left mb-3">
                  Repeat
                </div>
                <v-flex xs5>
                  <v-select
                    v-model="repeatType"
                    flat
                    outline
                    placeholder="Repeat"
                    label="Select"
                    class="dropdown-always-active-label"
                    style="border-radius: 5px;"
                    :items="repeatTypes"
                    :rules="repeatRules"
                  ></v-select>
                </v-flex>
              </v-flex>
              <v-flex xs8 v-if="lockSelection">
                <div class="H6-Secondary-Left mb-3">
                  Select Days
                </div>
                <v-flex xs6>
                  <v-item-group v-model="daysSelected" multiple>
                    <v-item v-for="(day, index) of daysOfWeek" :key="index" :value="day">
                      <v-chip
                        slot-scope="{ active, toggle }"
                        @click="toggle"
                        class="daysChips"
                        color="primary"
                        :outline="!active"
                        :text-color="active ? 'white' : 'black'"
                        label
                        :key="index"
                        >{{ day.charAt(0) }}</v-chip
                      >
                    </v-item>
                  </v-item-group>
                  <v-input disabled hidden v-model="daysSelected" :rules="daysSelectedRules" />
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout align-center class="my-3">
          <div
            v-if="showOverviewText"
            class="promo-schedule-text pa-3 Body-1-Black-High-Emphasis-Left pb-3 noselect"
          >
            Promotion will repeat <strong>{{ daysLabel }}</strong> between
            <strong>{{ timesLabel }}</strong> from
            <strong>{{ datesLabel }}</strong>
          </div>
        </v-layout>
      </div>
    </v-card>
  </v-layout>
</template>
<script>
import isEqual from 'lodash/isEqual';
import datePicker from '@/components/datePicker';
import timeSelect from '@/components/timeSelect';
import { DateTime } from 'luxon';
import rules from '@/rules';

export default {
  components: {
    datePicker,
    timeSelect,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      required: true,
      default: () => '',
    },
    isEnabled: {
      type: Object,
      required: false,
      default: () => undefined,
    },
    validateAllDayTime: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    today: DateTime.local().toISODate(),
    startDateRules: [rules.required('Start date cannot be empty.')],
    startTimeRules: [rules.required('Start time cannot be empty.')],
    repeatRules: [rules.required('Repeat cannot be empty.')],
    controlType: 'time',
    repeatTypes: ['Every Day', 'Weekdays', 'Weekends', 'Custom'],
    daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    weekdays: [1, 2, 3, 4, 5],
    weekend: [0, 6],
    everyday: [0, 1, 2, 3, 4, 5, 6],
  }),
  methods: {
    allDayPromo(slot) {
      if (slot && slot.is24Hours) {
        slot.startTime = '00:00';
        slot.endTime = '00:00';
      } else {
        slot.startTime = '';
        slot.endTime = '';
      }
    },
    endDateAfter(v) {
      if (!this.schedule.startDate) return true;
      const start = DateTime.fromISO(this.schedule.startDate);
      const end = DateTime.fromISO(v);
      return end >= start || 'End date cannot be before start date.';
    },
    endTimeAfter(v, i) {
      if (!this.schedule.startEndTimes[i].startTime) return true;
      const start = DateTime.fromFormat(this.schedule.startEndTimes[i].startTime, 'T');
      const end = DateTime.fromFormat(v, 'T');
      return end >= start || 'End time cannot be before start time.';
    },
    endTimeRules(v, i) {
      const required = (val) => {
        return !!val || 'End time cannot be empty.';
      };
      return [required, this.endTimeAfter(v, i)];
    },
    onPropChange(k, v) {
      this.$set(this.schedule, k, v);
    },
    addRemoveTimeslot() {
      if (this.schedule.startEndTimes.length === 1) {
        this.$set(this.schedule, 'startEndTimes', [
          this.schedule.startEndTimes[0],
          { startTime: '', endTime: '', is24Hours: false },
        ]);
      } else {
        this.$set(this.schedule, 'startEndTimes', [this.schedule.startEndTimes[0]]);
      }
    },
    getDayOfWeekAsInteger(day) {
      return this.daysOfWeek.indexOf(day);
    },
  },
  computed: {
    lockSelection() {
      return this.repeatType === 'Custom';
    },
    daysSelected: {
      get() {
        return this.schedule.days || [];
      },
      set(value) {
        const sorted = value.sort(
          (a, b) => this.daysOfWeek.indexOf(a) - this.daysOfWeek.indexOf(b),
        );
        this.$set(this.schedule, 'days', sorted);
      },
    },
    repeatType: {
      get() {
        if (!this.daysSelected) return '';
        const daysOfWeekNumbers =
          this.daysSelected && this.daysSelected.map((d) => this.getDayOfWeekAsInteger(d)).sort();
        if (isEqual(daysOfWeekNumbers, this.weekdays)) return 'Weekdays';
        if (isEqual(daysOfWeekNumbers, this.weekend)) return 'Weekends';
        if (isEqual(daysOfWeekNumbers, this.everyday)) return 'Every Day';
        return 'Custom';
      },
      set(value) {
        switch (value) {
          case 'Every Day':
            this.$set(this.schedule, 'days', this.daysOfWeek);
            break;
          case 'Weekdays':
            this.$set(this.schedule, 'days', this.daysOfWeek.slice(1, 6));
            break;
          case 'Weekends':
            this.$set(this.schedule, 'days', [this.daysOfWeek[0], this.daysOfWeek[6]]);
            break;
          case 'Custom':
            this.$set(this.schedule, 'days', []);
            break;
          default:
        }
      },
    },
    showOverviewText() {
      return (
        this.schedule.startDate &&
        this.schedule.endDate &&
        this.repeatType &&
        this.schedule.days.length > 0 &&
        this.schedule.startEndTimes[0].startTime &&
        this.schedule.startEndTimes[0].endTime
      );
    },
    daysLabel() {
      if (this.repeatType !== 'Custom') return this.repeatType;
      const { length } = this.schedule.days;
      return `Every ${[
        this.schedule.days.slice(0, length - 1).join(', '),
        this.schedule.days[length - 1],
      ]
        .filter((i) => i)
        .join(' and ')}`;
    },
    timesLabel() {
      const slots = [];
      this.schedule.startEndTimes.forEach((slot) => {
        const startTime = DateTime.fromFormat(slot.startTime, 'T');
        const endTime = DateTime.fromFormat(slot.endTime, 'T');
        if (startTime.isValid && endTime.isValid)
          slots.push(`${startTime.toFormat('t')} and ${endTime.toFormat('t')}`);
      });
      return slots.join(', ');
    },
    datesLabel() {
      return `${DateTime.fromFormat(this.schedule.startDate, 'yyyy-MM-dd').toFormat(
        'DDD',
      )} to ${DateTime.fromFormat(this.schedule.endDate, 'yyyy-MM-dd').toFormat('DDD')}`;
    },
    showAddTimeslot() {
      return this.schedule.startEndTimes.length === 1;
    },
    endDateRules() {
      const required = (v) => {
        return !!v || 'End date cannot be empty.';
      };
      return [required, this.endDateAfter];
    },
    daysSelectedRules() {
      const required = (v) => !!v.length || 'Select days cannot be empty.';
      return [required];
    },
  },
};
</script>
<style>
.daysChips {
  width: 40px;
  height: 40px;
  margin-right: 1px;
  margin-left: 1px;
  border-radius: 0;
  border: 1px solid black !important;
}
.v-chip__content {
  width: 100%;
  justify-content: center !important;
}
.v-chip--outline {
  width: 40px !important;
  height: 40px !important;
}
.promo-schedule-text {
  color: #595959 !important;
  width: 100%;
  min-height: 58px;
}
.CheckboxStylegroup {
  margin-top: -5px;
}

.CheckboxStyle {
  margin-left: 30px;
  height: 30px;
}
</style>
