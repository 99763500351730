import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "", "mt-4": "", "mb-4": "" } },
    [
      _c(
        VCard,
        { staticClass: "w100" },
        [
          _c(VCardTitle, { attrs: { "primary-title": "" } }, [
            _c(
              "div",
              {
                staticClass:
                  "H5-Primary-Left display-flex w100 justify-space-between"
              },
              [_vm._v("\n        Promotion Schedule\n      ")]
            )
          ]),
          _c(
            "div",
            { staticClass: "v-card-content" },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(VFlex, { attrs: { xs5: "" } }, [
                            _c(
                              "div",
                              { staticClass: "H6-Secondary-Left mb-3" },
                              [
                                _vm._v(
                                  "\n                Select Dates\n              "
                                )
                              ]
                            )
                          ]),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                VLayout,
                                { staticClass: "wrap" },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs3: "", "mr-3": "" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          "text-field-props": {
                                            "hide-details": false,
                                            label: "Start Date",
                                            rules: _vm.startDateRules
                                          },
                                          "picker-props": { min: _vm.today },
                                          value: _vm.schedule.startDate
                                        },
                                        on: {
                                          "update-date": function($event) {
                                            return _vm.onPropChange(
                                              "startDate",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("date-picker", {
                                        attrs: {
                                          "text-field-props": {
                                            "hide-details": false,
                                            disabled: false,
                                            label: "End Date",
                                            rules: _vm.endDateRules
                                          },
                                          "menu-props": { disabled: false },
                                          "picker-props": {
                                            min:
                                              _vm.schedule.startDate ||
                                              _vm.today
                                          },
                                          value: _vm.schedule.endDate
                                        },
                                        on: {
                                          "update-date": function($event) {
                                            return _vm.onPropChange(
                                              "endDate",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(VFlex, { attrs: { xs5: "" } }, [
                            _c(
                              "div",
                              { staticClass: "H6-Secondary-Left mb-3" },
                              [
                                _vm._v(
                                  "\n                Select Times\n              "
                                )
                              ]
                            )
                          ]),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _vm._l(_vm.schedule.startEndTimes, function(
                                slot,
                                index
                              ) {
                                return [
                                  _c(
                                    VLayout,
                                    { key: index, staticClass: "wrap" },
                                    [
                                      _c(
                                        VFlex,
                                        { attrs: { xs2: "", "mr-3": "" } },
                                        [
                                          _c("time-select", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              label: "Start Time",
                                              value: slot.startTime,
                                              rules: _vm.startTimeRules,
                                              autocomplete: "",
                                              disabled: slot.is24Hours
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  slot,
                                                  "startTime",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VFlex,
                                        { attrs: { xs2: "" } },
                                        [
                                          _c("time-select", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              label: "End Time",
                                              value: slot.endTime,
                                              rules: _vm.endTimeRules(
                                                slot.endTime,
                                                index
                                              ),
                                              type: _vm.controlType,
                                              autocomplete: "",
                                              disabled: slot.is24Hours
                                            },
                                            on: {
                                              "update:value": function($event) {
                                                return _vm.$set(
                                                  slot,
                                                  "endTime",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm.validateAllDayTime
                                        ? _c(
                                            VFlex,
                                            {
                                              staticClass: "CheckboxStylegroup",
                                              attrs: { xs2: "" }
                                            },
                                            [
                                              _c(VCheckbox, {
                                                staticClass: "CheckboxStyle",
                                                attrs: {
                                                  label: "24 Hour Promo",
                                                  color: "#0d73d8"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.allDayPromo(slot)
                                                  }
                                                },
                                                model: {
                                                  value: slot.is24Hours,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      slot,
                                                      "is24Hours",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "slot.is24Hours"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showAddTimeslot
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs3: "" } },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    flat: ""
                                                  },
                                                  on: {
                                                    click: _vm.addRemoveTimeslot
                                                  }
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        dark: ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  ),
                                                  _vm._v(
                                                    "\n                      Add Time Slot\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm.schedule.startEndTimes.length ===
                                            2 && index === 1
                                        ? _c(
                                            VFlex,
                                            { attrs: { xs3: "" } },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    flat: ""
                                                  },
                                                  on: {
                                                    click: _vm.addRemoveTimeslot
                                                  }
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        dark: ""
                                                      }
                                                    },
                                                    [_vm._v("mdi-minus")]
                                                  ),
                                                  _vm._v(
                                                    "\n                      Remove Time Slot\n                    "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs4: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "H6-Secondary-Left mb-3" },
                                [
                                  _vm._v(
                                    "\n                Repeat\n              "
                                  )
                                ]
                              ),
                              _c(
                                VFlex,
                                { attrs: { xs5: "" } },
                                [
                                  _c(VSelect, {
                                    staticClass: "dropdown-always-active-label",
                                    staticStyle: { "border-radius": "5px" },
                                    attrs: {
                                      flat: "",
                                      outline: "",
                                      placeholder: "Repeat",
                                      label: "Select",
                                      items: _vm.repeatTypes,
                                      rules: _vm.repeatRules
                                    },
                                    model: {
                                      value: _vm.repeatType,
                                      callback: function($$v) {
                                        _vm.repeatType = $$v
                                      },
                                      expression: "repeatType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.lockSelection
                            ? _c(
                                VFlex,
                                { attrs: { xs8: "" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "H6-Secondary-Left mb-3" },
                                    [
                                      _vm._v(
                                        "\n                Select Days\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs6: "" } },
                                    [
                                      _c(
                                        VItemGroup,
                                        {
                                          attrs: { multiple: "" },
                                          model: {
                                            value: _vm.daysSelected,
                                            callback: function($$v) {
                                              _vm.daysSelected = $$v
                                            },
                                            expression: "daysSelected"
                                          }
                                        },
                                        _vm._l(_vm.daysOfWeek, function(
                                          day,
                                          index
                                        ) {
                                          return _c(VItem, {
                                            key: index,
                                            attrs: { value: day },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var active = ref.active
                                                    var toggle = ref.toggle
                                                    return _c(
                                                      VChip,
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "daysChips",
                                                        attrs: {
                                                          color: "primary",
                                                          outline: !active,
                                                          "text-color": active
                                                            ? "white"
                                                            : "black",
                                                          label: ""
                                                        },
                                                        on: { click: toggle }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(day.charAt(0))
                                                        )
                                                      ]
                                                    )
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        }),
                                        1
                                      ),
                                      _c(VInput, {
                                        attrs: {
                                          disabled: "",
                                          hidden: "",
                                          rules: _vm.daysSelectedRules
                                        },
                                        model: {
                                          value: _vm.daysSelected,
                                          callback: function($$v) {
                                            _vm.daysSelected = $$v
                                          },
                                          expression: "daysSelected"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                { staticClass: "my-3", attrs: { "align-center": "" } },
                [
                  _vm.showOverviewText
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "promo-schedule-text pa-3 Body-1-Black-High-Emphasis-Left pb-3 noselect"
                        },
                        [
                          _vm._v("\n          Promotion will repeat "),
                          _c("strong", [_vm._v(_vm._s(_vm.daysLabel))]),
                          _vm._v(" between\n          "),
                          _c("strong", [_vm._v(_vm._s(_vm.timesLabel))]),
                          _vm._v(" from\n          "),
                          _c("strong", [_vm._v(_vm._s(_vm.datesLabel))])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }