<template>
  <v-card :class="vCardClass" :flat="true">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card-title v-if="title" class="H4-Secondary-Center px-4 pt-4">{{ title }}</v-card-title>
        <v-card-text v-if="description" class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
          {{ description }}
        </v-card-text>
      </v-flex>
    </v-layout>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12 md8>
          <v-text-field
            class="full-underline"
            label="Search Sites"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            clearable
          />
        </v-flex>
      </v-layout>
      <v-flex xs12>
        <v-layout row wrap class="mt-4">
          <v-flex xs12 md6>
            <p class="H6-Secondary-Left">All Sites</p>
            <v-list
              class="list-panel no-padding-top"
              :class="{
                'mr-2': $vuetify.breakpoint.mdAndUp,
                'mb-4': $vuetify.breakpoint.smAndDown,
              }"
            >
              <v-list-tile class="first-row-action">
                <v-list-tile-action>
                  <v-checkbox :disabled="isDisabled" :value="isSelectAll" @change="selectAll" />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Select All</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-for="site of filterBy(availableSites, search, 'name')" :key="site.id">
                <v-list-tile-action>
                  <v-checkbox
                    v-model="isChecked[site.id]"
                    @change="addSite(site)"
                    :value="isChecked[site.id]"
                    :label="site.name"
                  />
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-flex>
          <v-flex xs12 md6>
            <p class="H6-Secondary-Left">Sites Selected ({{ selectedSitesProxy.length }})</p>
            <v-list class="list-panel no-padding-top">
              <v-list-tile class="first-row-action">
                <v-list-tile-action>
                  <v-icon :disabled="isDisabled" @click="removeAll">mdi-close-circle</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Remove All</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile
                v-for="site of filterBy(selectedSitesProxy, search, 'name')"
                :key="site.id"
              >
                <v-list-tile-action>
                  <v-icon @click="removeSite(site)">mdi-close-circle</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ site.name }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import filters from 'vue2-filters';

export default {
  mixins: [filters.mixin],
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    selectedSites: {
      type: Array,
      required: true,
    },
    siteMap: {
      type: Object,
      required: true,
    },
    noShadow: {
      default: false,
    },
    noOffset: {
      default: false,
    },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    availableSites() {
      return Object.values(this.siteMap);
    },
    isChecked() {
      return this.selectedSitesProxy.reduce((sites, site) => {
        sites[site.id] = true;
        return sites;
      }, {});
    },
    isDisabled() {
      return !!this.search;
    },
    isSelectAll() {
      return this.selectedSitesProxy.length === this.availableSites.length;
    },
    selectedSitesProxy: {
      get() {
        return this.selectedSites;
      },
      set(v) {
        this.$emit('update:selectedSites', v);
      },
    },
    vCardClass() {
      let _class = '';
      if (this.noShadow) {
        _class += 'no-shadow ';
      }
      if (this.noOffset) {
        _class += 'no-offset ';
      }
      return _class;
    },
  },
  methods: {
    selectAll() {
      if (this.isSelectAll) {
        this.removeAll();
        return null;
      }

      this.selectedSitesProxy = this.availableSites.map((site) => ({
        id: site.id,
        name: site.name,
      }));
      return null;
    },
    removeAll() {
      this.selectedSitesProxy = [];
      return null;
    },
    addSite(site) {
      if (this.selectedSitesProxy.find((i) => i.id === site.id)) {
        this.removeSite(site);
        return null;
      }

      this.selectedSitesProxy = [
        ...this.selectedSitesProxy,
        {
          id: site.id,
          name: site.name,
        },
      ];

      return this.selectedSitesProxy;
    },
    removeSite(site) {
      const updatedArr = [...this.selectedSitesProxy];
      const index = updatedArr.findIndex((i) => i.id === site.id);

      if (index === -1) {
        return null;
      }

      updatedArr.splice(index, 1);
      this.selectedSitesProxy = updatedArr;
      return this.selectedSitesProxy;
    },
  },
};
</script>

<style scoped>
.list-panel {
  border: 1px solid #000;
  height: 300px;
  overflow-y: scroll;
}
.v-card.no-shadow {
  box-shadow: none;
}
.v-card.no-offset .v-card__text {
  padding-left: 0;
}
.list-panel >>> .v-input__slot {
  margin-bottom: 0;
}

.v-list.no-padding-top {
  padding-top: 0;
}

.first-row-action {
  background-color: #e8e8e8;
}

.full-underline .v-input__prepend-outer:before {
  border-color: rgba(0, 0, 0, 0.42);
}

.full-underline .v-input__prepend-outer:before {
  border-style: solid;
  border-width: thin 0 0 0;
}
.full-underline .v-input__prepend-outer:before,
.full-underline .v-input__prepend-outer:after {
  bottom: -1px;
  content: '';
  left: 0;
  position: absolute;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
</style>
