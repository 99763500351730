<template>
  <div>
    <v-data-table
      ref="table"
      v-bind="combinedSelectedTableProps"
      select-all
      :items="items"
      :value="selectedItems"
    >
      <template v-slot:headers="props">
        <th scope="col">
          <v-checkbox
            :input-value="props.all"
            :indeterminate="props.indeterminate"
            primary
            hide-details
            @click.stop="onSelectAll"
          ></v-checkbox>
        </th>
        <th
          v-for="header in props.headers"
          :key="header.text"
          :class="['column', `text-xs-${header.align || 'left'}`]"
          scope="col"
        >
          {{ header.text }}
        </th>
      </template>
      <template v-slot:items="props">
        <tr @click="!isDisabled(props) && onSelectRow(props)" :key="props.item.id">
          <td>
            <v-checkbox
              :disabled="isDisabled(props)"
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td
            v-for="(header, index) in combinedSelectedTableProps.headers"
            :key="header.text"
            :class="`text-xs-${header.align || 'left'}`"
            :scope="index === 0 ? 'row' : undefined"
          >
            {{ header.format === 'currency' && get(props.item, header.value, '') | currency }}
            {{ header.format !== 'currency' ? get(props.item, header.value, '') : '' }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import merge from 'lodash/merge';
import get from 'lodash/get';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    selectionTableProps: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    get,
    selectedTableDefaultProps: {
      class: 'pb-3 px-3',
      headers: [
        {
          text: 'Item Name',
          align: 'left',
          value: 'label.en',
        },
        {
          text: 'Price',
          align: 'right',
          format: 'currency',
          value: 'price.amount',
        },
      ],
      'item-key': 'id',
      'hide-actions': true,
      'no-data-text': 'No items found',
    },
  }),
  computed: {
    combinedSelectedTableProps() {
      return merge(this.selectedTableDefaultProps, this.selectionTableProps);
    },
  },
  methods: {
    isDisabled(props) {
      if (!this.limit || props.selected) {
        return false;
      }

      if (this.limit === this.selectedItems.length) {
        return true;
      }

      return false;
    },
    onSelectRow(props) {
      const index = this.selectedItems.findIndex((item) => item.id === props.item.id);

      // Add item to selected
      if (index === -1) {
        this.$emit('update:selectedItems', [...this.selectedItems, props.item]);
        return;
      }

      // Remove item
      const updatedItems = [...this.selectedItems];
      updatedItems.splice(index, 1);
      this.$emit('update:selectedItems', updatedItems);
    },
    onSelectAll() {
      const { table } = this.$refs;
      let updatedItems = [...this.selectedItems];

      // Select All but limit reached
      if (!table.everyItem && this.limit && updatedItems.length === this.limit) {
        return;
      }

      // Select All
      if (!table.everyItem) {
        for (const item of this.items) {
          if (!this.selectedItems.find((selected) => selected.id === item.id)) {
            updatedItems.push(item);
          }

          // Limit reached while selecting all
          if (this.limit && updatedItems.length === this.limit) {
            this.$emit('update:selectedItems', updatedItems);
            break;
          }
        }
      } else {
        // Select None
        updatedItems = this.selectedItems.filter(
          (selected) => !this.items.find((item) => selected.id === item.id),
        );
      }

      this.$emit('update:selectedItems', updatedItems);
    },
  },
};
</script>
