import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VDataTable,
        _vm._b(
          {
            ref: "table",
            attrs: {
              "select-all": "",
              items: _vm.items,
              value: _vm.selectedItems
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "th",
                      { attrs: { scope: "col" } },
                      [
                        _c(VCheckbox, {
                          attrs: {
                            "input-value": props.all,
                            indeterminate: props.indeterminate,
                            primary: "",
                            "hide-details": ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onSelectAll($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._l(props.headers, function(header) {
                      return _c(
                        "th",
                        {
                          key: header.text,
                          class: [
                            "column",
                            "text-xs-" + (header.align || "left")
                          ],
                          attrs: { scope: "col" }
                        },
                        [
                          _vm._v(
                            "\n        " + _vm._s(header.text) + "\n      "
                          )
                        ]
                      )
                    })
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      {
                        key: props.item.id,
                        on: {
                          click: function($event) {
                            !_vm.isDisabled(props) && _vm.onSelectRow(props)
                          }
                        }
                      },
                      [
                        _c(
                          "td",
                          [
                            _c(VCheckbox, {
                              attrs: {
                                disabled: _vm.isDisabled(props),
                                "input-value": props.selected,
                                primary: "",
                                "hide-details": ""
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(_vm.combinedSelectedTableProps.headers, function(
                          header,
                          index
                        ) {
                          return _c(
                            "td",
                            {
                              key: header.text,
                              class: "text-xs-" + (header.align || "left"),
                              attrs: { scope: index === 0 ? "row" : undefined }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      header.format === "currency" &&
                                        _vm.get(props.item, header.value, "")
                                    )
                                  ) +
                                  "\n          " +
                                  _vm._s(
                                    header.format !== "currency"
                                      ? _vm.get(props.item, header.value, "")
                                      : ""
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              }
            ])
          },
          "v-data-table",
          _vm.combinedSelectedTableProps,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }