<template>
  <v-layout row wrap>
    <v-card class="w100">
      <v-card-title primary-title>
        <div class="H5-Primary-Left">Promotion Name</div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout>
          <v-flex xs12 md7>
            <v-layout class="wrap">
              <v-flex xs12>
                <v-text-field v-model="name" label="Promotion Name" :rules="nameRules" />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  outline
                  auto-grow
                  label="Promotion Description"
                  rows="4"
                  v-model="description"
                  :rules="descriptionRules"
                  maxlength="240"
                  counter="240"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
    </v-card>
  </v-layout>
</template>

<script>
import rules from '@/rules';

export default {
  props: ['promo'],
  data: () => ({
    nameRules: [rules.required('Promotion Name is Required')],
    descriptionRules: [rules.required('Promotion Description is Required')],
  }),
  computed: {
    promoProxy: {
      get() {
        return this.promo;
      },
      set(value) {
        this.$emit('update:promo', value);
      },
    },
    name: {
      get() {
        return this.promo.label.en;
      },
      set(value) {
        this.$set(this.promoProxy, 'label', { en: value });
      },
    },
    description: {
      get() {
        return this.promo.description.en;
      },
      set(value) {
        this.$set(this.promoProxy, 'description', { en: value });
      },
    },
  },
  watch: {
    name() {
      this.$emit('update:isNameUnique', true);
    },
  },
};
</script>
