import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-md": "" } },
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "settings",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            VLayout,
            { staticClass: "wrap" },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("promo-name", {
                    attrs: { promo: _vm.promo },
                    on: {
                      "update:promo": function($event) {
                        _vm.promo = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("promo-location", {
                    attrs: {
                      multigroup: _vm.multigroupProxy,
                      company: _vm.companyProxy,
                      locationGroups: _vm.promo.location_group_ids,
                      heading: "Promotion Locations",
                      subHeading: "Promotions will be active across all",
                      subHeading1: "at the selected sites",
                      selectBrandLabel: "Select Brand (Global Menu)",
                      showLocationFilter: true,
                      confirmMultigroupChange: true,
                      confirmCompanyChange: true,
                      multigroupsMap: _vm.multigroupsMap,
                      selectSitesSubheading: "Choose sites to enable promotions"
                    },
                    on: {
                      "update:multigroup": function($event) {
                        _vm.multigroupProxy = $event
                      },
                      "update:company": function($event) {
                        _vm.companyProxy = $event
                      },
                      "update:locationGroups": function($event) {
                        return _vm.$set(_vm.promo, "location_group_ids", $event)
                      },
                      "update:location-groups": function($event) {
                        return _vm.$set(_vm.promo, "location_group_ids", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("promo-schedule", {
                    attrs: {
                      label: "Promotion",
                      schedule: _vm.promoCalendar.schedule,
                      validateAllDayTime: true
                    },
                    on: {
                      "update:schedule": function($event) {
                        return _vm.$set(_vm.promoCalendar, "schedule", $event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                {
                  staticStyle: { "padding-bottom": "50px" },
                  attrs: { xs12: "" }
                },
                [
                  _c("promo-details", {
                    attrs: {
                      promo: _vm.promo,
                      companyValue: _vm.companyValue,
                      promoTypeValues: _vm.promoTypeValues,
                      isNewPromo: _vm.isNewPromo
                    },
                    on: {
                      "update:promo": function($event) {
                        _vm.promo = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("save-footer", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isModified,
                        expression: "isModified"
                      }
                    ],
                    attrs: {
                      cancelAction: _vm.cancel,
                      saveLabel: _vm.isNewPromo
                        ? "Create Promotion"
                        : "Save Changes",
                      saveAction: _vm.save,
                      showCreateIcon: true
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }