import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "", "mt-4": "", "mb-4": "" } },
    [
      _c(
        VCard,
        { staticClass: "w100" },
        [
          _c(VCardTitle, { attrs: { "primary-title": "" } }, [
            _c(
              "div",
              {
                staticClass:
                  "H5-Primary-Left display-flex w100 justify-space-between"
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.heading))]),
                _c(
                  "div",
                  [
                    _c(
                      VTooltip,
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "div",
                                  _vm._g({}, on),
                                  [
                                    _c(
                                      VIcon,
                                      {
                                        staticClass:
                                          "icon clickable refresh-icon",
                                        on: { click: _vm.refetchData }
                                      },
                                      [_vm._v("mdi-refresh")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [_c("span", [_vm._v("Refetch data")])]
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "v-card-content" },
            [
              _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs5: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "H6-Secondary-Left mb-3" },
                                [
                                  _vm._v(
                                    "\n                  Select App\n                "
                                  )
                                ]
                              ),
                              _c(VSelect, {
                                ref: "selectMultigroup",
                                staticClass: "dropdown-always-active-label",
                                attrs: {
                                  value: _vm.multigroupProxy,
                                  items: _vm.multigroups,
                                  "item-text": "name",
                                  label: "App",
                                  "return-object": "",
                                  outline: "",
                                  rules: _vm.multigroupRules,
                                  loading: _vm.loading
                                },
                                on: { change: _vm.changeMultigroup }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs5: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "H6-Secondary-Left mb-3" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.selectBrandLabel) +
                                      "\n                "
                                  )
                                ]
                              ),
                              _c(VAutocomplete, {
                                staticClass: "dropdown-always-active-label",
                                attrs: {
                                  value: _vm.companyProxy,
                                  items: _vm.companiesAvailable,
                                  "item-text": function(company) {
                                    return company.name
                                  },
                                  "item-value": "id",
                                  label: "Brands",
                                  "return-object": "",
                                  outline: "",
                                  rules: _vm.companyRules
                                },
                                on: { change: _vm.changeCompany }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showLocationFilter
                        ? _c(
                            VLayout,
                            {
                              staticClass: "wrap f-column",
                              staticStyle: { "padding-left": "4px" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "H6-Secondary-Left mb-3" },
                                [_vm._v("Filter by Location")]
                              ),
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    {
                                      staticStyle: { "padding-right": "20px" },
                                      attrs: { xs12: "", md3: "" }
                                    },
                                    [
                                      _c(VSelect, {
                                        staticClass:
                                          "dropdown-always-active-label",
                                        attrs: {
                                          items: _vm.countriesAvailable,
                                          label: "Country (optional)",
                                          outline: "",
                                          "item-text": "name",
                                          "item-value": "id",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changeCountry },
                                        model: {
                                          value: _vm.selectedCountry,
                                          callback: function($$v) {
                                            _vm.selectedCountry = $$v
                                          },
                                          expression: "selectedCountry"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    {
                                      staticStyle: { "padding-right": "20px" },
                                      attrs: { xs12: "", md3: "" }
                                    },
                                    [
                                      _c(VSelect, {
                                        staticClass:
                                          "dropdown-always-active-label",
                                        attrs: {
                                          items: _vm.statesAvailable,
                                          label: _vm.stateLabel,
                                          outline: "",
                                          "item-text": "name",
                                          "return-object": ""
                                        },
                                        on: { change: _vm.changeState },
                                        model: {
                                          value: _vm.selectedState,
                                          callback: function($$v) {
                                            _vm.selectedState = $$v
                                          },
                                          expression: "selectedState"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    { attrs: { xs12: "", md3: "" } },
                                    [
                                      _c(VSelect, {
                                        staticClass:
                                          "dropdown-always-active-label",
                                        attrs: {
                                          items: _vm.citiesAvailable,
                                          label: "City (optional)",
                                          outline: "",
                                          "item-text": "name",
                                          "return-object": ""
                                        },
                                        model: {
                                          value: _vm.selectedCity,
                                          callback: function($$v) {
                                            _vm.selectedCity = $$v
                                          },
                                          expression: "selectedCity"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VLayout,
                        { staticClass: "wrap" },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs10: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "H6-Secondary-Left mb-3" },
                                [_vm._v("Select Sites")]
                              ),
                              _vm.selectSitesSubheading
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.selectSitesSubheading) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("site-filter", {
                                attrs: {
                                  description: _vm.siteFilterDesc,
                                  selectedSites: _vm.location_groups,
                                  siteMap: _vm.sitesFilteredMap,
                                  showInDialog: false,
                                  noOffset: true,
                                  noShadow: true
                                },
                                on: {
                                  "update:selectedSites": function($event) {
                                    _vm.location_groups = $event
                                  },
                                  "update:selected-sites": function($event) {
                                    _vm.location_groups = $event
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VLayout,
                [
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _vm.subHeading && _vm.company.name
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                          },
                          [
                            _vm._v(
                              "\n              " + _vm._s(_vm.subHeading) + " "
                            ),
                            _c("strong", [_vm._v(_vm._s(_vm.company.name))]),
                            _vm._v(
                              " " + _vm._s(_vm.subHeading1) + "\n            "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._t("validation")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }