<template>
  <content-loader
    ref="content"
    :width="parentWidth"
    :height="parentHeight"
    :speed="2"
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect :x="margin[1]" :y="0" rx="3" ry="3" :width="blockWidth" :height="height" />
    <rect
      v-for="index in rows - 1"
      :key="index"
      :x="margin[1]"
      :y="index * (height + margin[0])"
      rx="3"
      ry="3"
      :width="blockWidth"
      :height="height"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  props: {
    height: {
      type: Number,
      default: 50,
    },
    rows: {
      type: Number,
      default: 1,
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
  },
  components: { ContentLoader },
  data: () => ({
    parentWidth: 0,
    margin: [10, 20],
  }),
  computed: {
    blockWidth() {
      // If number passed, used that number exactly
      if (!this.width.includes('%')) {
        return this.width;
      }

      // Use percentage
      const percentage = parseInt(this.width.split('%')[0] || 100, 10) / 100;
      return Math.max((this.parentWidth - this.margin[1]) * percentage, 0);
    },
    parentHeight() {
      return (this.height + this.margin[0]) * this.rows;
    },
  },
  mounted() {
    const [computedWidth] = window
      .getComputedStyle(this.$refs.content.parentElement)
      .getPropertyValue('width')
      .split('px');
    this.parentWidth = parseInt(computedWidth, 10);
  },
};
</script>
