<template>
  <v-card class="pt-3">
    <v-layout row>
      <v-flex xs4>
        <v-flex xs12>
          <div :class="['ml-3', 'H5-Primary-Left', { 'mb-3': !limit, 'mb-0': limit }]">
            {{ company.name }}
          </div>
          <v-flex xs12 v-if="limit">
            <div class="ml-3 mb-3">Selected {{ selectedItems.length }} of {{ limit }}</div>
          </v-flex>
        </v-flex>
        <placeholder :loading="loading">
          <template #loading>
            <block-placeholder :rows="3" />
          </template>
          <div v-if="!menuLength" class="d-block pa-0 pl-3 mt-3 text-xs-center py-3 px-3">
            No Menus were found for this brand.
          </div>
          <v-expansion-panel class="panel" v-else>
            <v-expansion-panel-content lazy v-for="menu in menus" :key="menu.id">
              <template v-slot:header>
                <div @click="loadMenuGroups(menu.id)" class="menu py-3 px-3">
                  {{ menu.name }}
                </div>
              </template>
              <template v-slot:actions>
                <div @click="loadMenuGroups(menu.id)" class="menu py-3 px-3">
                  <v-icon>$vuetify.icons.expand</v-icon>
                </div>
              </template>
              <placeholder :loading="menus[menu.id].loading">
                <template #loading>
                  <div class="placeholder">
                    <block-placeholder :rows="3" :height="30" />
                  </div>
                </template>
                <div
                  v-if="!groupLength(menu.id)"
                  class="d-block pa-0 pl-3 mt-3 text-xs-center py-3 px-3"
                >
                  No categories were found for this menu.
                </div>
                <v-list v-else>
                  <v-list-tile
                    @click="setActive(menu.id, group.id)"
                    v-for="group in groups[menu.id]"
                    :key="group.id"
                  >
                    <v-list-tile-content class="panel-content">
                      <v-checkbox
                        readonly
                        class="ma-0 pa-0"
                        v-bind="checkboxState(menu.id, group.id)"
                        :ripple="false"
                        primary
                        hide-details
                      ></v-checkbox>
                      <v-list-tile-title class="group ml-3" v-text="group.name"></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </placeholder>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </placeholder>
      </v-flex>
      <v-flex xs8 class="table-container">
        <v-card-title class="d-block py-3 pl-3" primary-title v-if="active.group">
          <h3 class="headline mb-0">{{ groups[active.menu][active.group].name }}</h3>
          <div>{{ menus[active.menu].name }}</div>
        </v-card-title>
        <v-card-title class="d-block pa-0 pl-3 mt-3 text-xs-center" primary-title v-else>
          {{ noActiveMenuText }}
        </v-card-title>
        <menu-item-table
          v-if="active.group"
          :items="tableItems"
          :limit="limit"
          :selectedItems.sync="selectedItems"
          :selectionTableProps="selectionTableProps"
        />
      </v-flex>
    </v-layout>
    <v-card-actions class="actions">
      <v-spacer></v-spacer>
      <v-btn color="blue" flat @click="onCancel">Cancel</v-btn>
      <v-btn class="mr-2" color="primary" @click="onSave">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import Placeholder from '@/components/Placeholder';
import BlockPlaceholder from '@/components/Placeholder/Block';
import MenuItemTable from '@/components/MenuItemSelect/Table';

export default {
  components: { MenuItemTable, Placeholder, BlockPlaceholder },
  props: {
    company: {
      type: Object,
      required: true,
      validator: (config) => ['id', 'name'].every((key) => key in config),
    },
    items: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    noActiveMenuText: {
      type: String,
      required: true,
    },
    selectionTableProps: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    active: {
      menu: null,
      group: null,
    },
    selectedItems: [],
    headers: [
      {
        text: 'Item Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Price',
        align: 'left',
        value: 'price',
      },
    ],
    loading: false,
    menus: {},
    groups: {},
  }),
  computed: {
    menuLength() {
      return Object.keys(this.menus).length;
    },
    tableItems() {
      if (!this.active.group) {
        return [];
      }

      const { loading, ...menu } = this.menus[this.active.menu];
      const { items, ...group } = this.groups[this.active.menu][this.active.group];

      return this.groups[this.active.menu][this.active.group].items.map((item) => ({
        ...item,
        menu,
        group,
      }));
    },
  },
  methods: {
    ...mapActions('menus', ['fetchCompanyMenu', 'fetchMenu']),
    setActive(menu, group) {
      this.active = { menu, group };
    },
    async loadMenuGroups(id) {
      this.$set(this.menus[id], 'loading', true);

      try {
        const menu = await this.fetchMenu({ id });
        menu.groups.forEach((group) => {
          this.$set(this.groups[id], group.id, {
            id: group.id,
            name: group.label.en,
            items: group.items,
          });
        });
      } catch (e) {
        console.error(e);
        this.$toast.error('Error loading menu groups. Please try again.');
      } finally {
        this.$set(this.menus[id], 'loading', false);
      }
    },
    async loadCompanyMenus({ id }) {
      if (!id) return;
      this.loading = true;
      try {
        const { menus } = await this.fetchCompanyMenu({ id });
        menus.forEach((menu) => {
          // No local menus
          // TODO: add ability to choose local?
          if (menu.parent_id || !menu.label || !menu.label.en) {
            return;
          }

          // Add menu to menu list
          this.menus = {
            ...this.menus,
            [menu.id]: {
              id: menu.id,
              loading: false,
              name: menu.label.en,
            },
          };

          // Add menu to groups list
          this.groups = {
            ...this.groups,
            [menu.id]: {},
          };
        });
      } catch (e) {
        console.error(e);
        this.$toast.error('Error loading company menu. Please try again.');
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.active = {
        menu: null,
        group: null,
      };

      this.selectedItems = [...this.items];
      this.$emit('cancel-dialog');
    },
    onSave() {
      this.active = {
        menu: null,
        group: null,
      };

      this.$emit('save-dialog', this.selectedItems);
    },
    checkboxState(menu, group) {
      const { items } = this.groups[menu][group];

      if (!items.length) {
        return {
          value: false,
          indeterminate: false,
        };
      }

      const selectedItemsFromGroup = this.selectedItems.filter((selectedItem) =>
        items.find((item) => item.id === selectedItem.id),
      );
      return {
        value: selectedItemsFromGroup.length === items.length,
        indeterminate:
          selectedItemsFromGroup.length > 0 && selectedItemsFromGroup.length < items.length,
      };
    },
    groupLength(id) {
      return Object.keys(this.groups[id]).length;
    },
  },
  watch: {
    async company(value) {
      this.menus = {};
      this.groups = {};

      if (value) {
        await this.loadCompanyMenus(value);
      }
    },
    items(value) {
      this.selectedItems = [...value];
    },
  },
  async mounted() {
    this.selectedItems = [...this.items];
    await this.loadCompanyMenus(this.company);
  },
};
</script>

<style scoped>
.actions {
  background: #fff;
  bottom: -1px;
  position: sticky;
  width: 100%;
}
.group {
  font-size: 14px;
}
.menu {
  font-size: 16px;
}
.table-container {
  min-height: 650px;
}
.panel {
  box-shadow: none;
}

.panel >>> .v-expansion-panel__header {
  padding: 0px;
  min-height: unset;
}

.panel-content {
  align-items: center;
  flex-direction: row;
}
.placeholder {
  height: 200px;
  width: 265px;
}
</style>
