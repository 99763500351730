<template>
  <v-dialog v-bind="combinedDialogProps" v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="{ on }">
        <v-btn v-on="on" v-bind="combinedButtonProps">
          <v-icon>mdi-plus</v-icon>
          {{ dialogBtnTxt ? dialogBtnTxt : `Add Item${limit == 1 ? '' : '(s)'}` }}
        </v-btn>
      </slot>
    </template>
    <menu-item-select-card
      :company="company"
      :items="items"
      :limit="limit"
      :noActiveMenuText="noActiveMenuText"
      :selectionTableProps="selectionTableProps"
      @save-dialog="onSave"
      @cancel-dialog="dialog = false"
    />
  </v-dialog>
</template>

<script>
import merge from 'lodash/merge';
import MenuItemSelectCard from '@/components/MenuItemSelect/Card';

export default {
  components: { MenuItemSelectCard },
  props: {
    company: {
      type: Object,
      validator: (config) => ['id', 'name'].every((key) => key in config),
    },
    dialogBtnTxt: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      default: 0,
    },
    noActiveMenuText: {
      type: String,
      default: 'Choose a menu to view items',
    },
    selectionTableProps: {
      type: Object,
      default() {
        return {};
      },
    },
    vDialogBtnProps: {
      type: Object,
      default() {
        return {};
      },
    },
    vDialogProps: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    dialog: false,
    vDialogBtnDefaultProps: {
      class: 'ma-0 mt-4',
      color: 'primary',
    },
    vDialogDefaultProps: {
      lazy: true,
      width: 800,
    },
  }),
  computed: {
    combinedButtonProps() {
      return merge(this.vDialogBtnDefaultProps, this.vDialogBtnProps);
    },
    combinedDialogProps() {
      return merge(this.vDialogDefaultProps, this.vDialogProps);
    },
  },
  methods: {
    onSave(items) {
      this.dialog = false;
      this.$emit('update:items', items);
    },
  },
};
</script>
