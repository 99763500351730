<template>
  <v-layout row wrap>
    <v-flex xs4>
      Item Name
      <div class="H6-Selected-On-Surface-Medium-Emphasis-Left">
        {{ selectedItem.label.en }}
      </div>
    </v-flex>
    <v-flex xs6>
      <v-layout row wrap>
        <v-flex xs4>
          <span v-if="showQuantity">
            Quantity
            <div style="margin-top:-20px">
              <v-text-field
                type="number"
                v-model="quantity"
                min="1"
                outline
                :rules="quantityRules"
              ></v-text-field></div
          ></span>
        </v-flex>
        <v-flex xs4>
          <span v-if="showBogoControl"
            >Discount Type
            <div style="margin-top:-20px">
              <v-select
                v-model="itemDiscountType"
                class="dropdown-always-active-label"
                :items="discountTypes"
                item-text="name"
                item-value="id"
                :rules="discountTypeRules"
                outline
              ></v-select></div
          ></span>
        </v-flex>
        <v-flex xs4>
          <span v-if="showBogoControl">
            {{ discountValueLabel }}
            <div style="margin-top:-20px">
              <v-text-field
                type="number"
                v-model="itemDiscountValue"
                :disabled="discountValueDisabled"
                :rules="discountValueRules"
                :prepend-inner-icon="discountValueIcon"
              /></div
          ></span>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3>
          Price Per Item
          <div class="H6-Selected-On-Surface-Medium-Emphasis-Left">$ {{ globalItemPrice }}</div>
        </v-flex>
        <v-flex xs3>
          Total Item Price
          <div class="H6-Selected-On-Surface-Medium-Emphasis-Left">$ {{ totalItemPrice }}</div>
        </v-flex>
        <v-flex xs3 v-if="showBogoControl">
          Discounted Price
          <div class="H6-Selected-On-Surface-Medium-Emphasis-Left">$ {{ discountedItemPrice }}</div>
        </v-flex>
        <v-flex xs3 v-if="showBogoControl">
          Discount Given
          <div class="H6-Selected-On-Surface-Medium-Emphasis-Left">$ {{ itemDiscountGiven }}</div>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs1>
      <menu-item-select-dialog
        :company="company"
        :items="itemToEdit"
        :limit="1"
        @update:items="editItem($event)"
      >
        <template v-slot:activator="{ on }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: vTooltipOn }">
              <v-btn
                v-on="{ ...on, ...vTooltipOn }"
                flat
                icon
                color="#0d73d8"
                @click="$emit('selectedItem:edit')"
                class="buttonActions"
              >
                <v-icon>mdi-pencil</v-icon>
                EDIT
              </v-btn>
            </template>
            <span>Edit Item</span>
          </v-tooltip>
        </template>
      </menu-item-select-dialog>
    </v-flex>
    <v-flex xs1>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            flat
            icon
            color="#0d73d8"
            @click="$emit('selectedItem:delete')"
            class="buttonActions"
          >
            <v-icon>mdi-delete</v-icon>
            REMOVE
          </v-btn>
        </template>
        <span>Remove Item</span>
      </v-tooltip>
    </v-flex>
    <v-flex xs12><v-divider></v-divider></v-flex>
  </v-layout>
</template>

<script>
import isNil from 'lodash/isNil';
import rules from '@/rules';
import MenuItemSelectDialog from '@/components/MenuItemSelect/Dialog';

export default {
  components: {
    MenuItemSelectDialog,
  },
  props: [
    'selectedItem',
    'promoType',
    'promoTypeValues',
    'itemSelectionTypeValue',
    'itemSelectionType',
    'company',
  ],
  data: () => ({
    quantityRules: [rules.greaterThan(0), rules.required('Quantity is Required')],
    discountTypes: [
      { name: 'No Discount', id: null },
      { name: '$ Discount Amount', id: 1 },
      { name: '% Percentage Off', id: 2 },
    ],
    discountTypeValues: {
      noDiscount: null,
      discountAmount: 1,
      percentageOff: 2,
    },
    itemToEdit: [],
  }),
  computed: {
    discountTypeRules() {
      return this.promoType === this.promoTypeValues.bundle &&
        this.itemSelectionTypeValue === this.itemSelectionType.main
        ? []
        : [rules.required('Discount Type is Required')];
    },
    globalItemPrice() {
      return ((this.selectedItemProxy.price && this.selectedItemProxy.price.amount) || 0).toFixed(
        2,
      );
    },
    totalItemPrice() {
      return (this.globalItemPrice * this.quantity).toFixed(2);
    },
    selectedItemProxy: {
      get() {
        return this.selectedItem;
      },
      set(value) {
        this.$emit('update:selectedItem', value);
      },
    },
    quantity: {
      get() {
        return this.selectedItem.quantity || 0;
      },
      set(value) {
        this.$set(this.selectedItemProxy, 'quantity', parseInt(value, 0));
      },
    },
    itemDiscountType: {
      get() {
        if (!isNil(this.selectedItem.discount.amount_off))
          return this.discountTypeValues.discountAmount;
        if (!isNil(this.selectedItem.discount.percent_off))
          return this.discountTypeValues.percentageOff;
        return this.discountTypeValues.noDiscount;
      },
      set(value) {
        let amount_off = null;
        let percent_off = null;
        if (value === this.discountTypeValues.discountAmount) {
          amount_off = 0;
        }
        if (value === this.discountTypeValues.percentageOff) {
          percent_off = 0;
        }

        this.$set(this.selectedItemProxy, 'discount', {
          amount_off,
          percent_off,
        });
      },
    },
    itemDiscountValue: {
      get() {
        if (this.itemDiscountType === this.discountTypeValues.discountAmount)
          return this.selectedItem.discount.amount_off;
        if (this.itemDiscountType === this.discountTypeValues.percentageOff)
          return this.selectedItem.discount.percent_off;
        return null;
      },
      set(value) {
        this.setDiscountAmounts(this.itemDiscountType, value);
      },
    },
    discountValueRules() {
      const rule = [rules.greaterThan(0)];

      if (this.itemDiscountType === this.discountTypeValues.discountAmount) {
        rule.push(rules.max(this.selectedItem.price.amount * this.selectedItem.quantity));
        return rule;
      }
      if (this.itemDiscountType === this.discountTypeValues.percentageOff)
        rule.push(rules.max(100));

      if (
        this.promoType === this.promoTypeValues.bundle &&
        this.itemSelectionTypeValue === this.itemSelectionType.main
      ) {
        return rule;
      }

      rule.push(rules.required('Discount Value is Required'));
      return rule;
    },
    discountValueDisabled() {
      return isNil(this.itemDiscountType);
    },
    discountValueLabel() {
      if (this.itemDiscountType === this.discountTypeValues.percentageOff) return '% Discount';
      return '$ Discount Amount';
    },
    discountValueIcon() {
      if (this.itemDiscountType === this.discountTypeValues.discountAmount)
        return 'mdi-currency-usd';
      if (this.itemDiscountType === this.discountTypeValues.percentageOff) return 'mdi-percent';
      return null;
    },
    showBogoControl() {
      return (
        this.promoType !== this.promoTypeValues.bogo ||
        this.itemSelectionTypeValue === this.itemSelectionType.free
      );
    },
    showQuantity() {
      return this.promoType !== this.promoTypeValues.lto;
    },
    discountedItemPrice() {
      if (this.quantity === 0) return 0;
      let calculatedPrice = this.globalItemPrice * this.quantity;
      if (this.itemDiscountType === this.discountTypeValues.discountAmount) {
        calculatedPrice -= this.itemDiscountValue;
      }
      if (this.itemDiscountType === this.discountTypeValues.percentageOff) {
        calculatedPrice -= (this.itemDiscountValue / 100) * calculatedPrice;
      }
      return calculatedPrice.toFixed(2);
    },
    itemDiscountGiven() {
      return (this.totalItemPrice - this.discountedItemPrice).toFixed(2);
    },
  },
  methods: {
    async setDiscountAmounts(discountType, discountValue) {
      let amount_off = null;
      let percent_off = null;
      if (discountType === this.discountTypeValues.discountAmount) {
        amount_off = parseFloat(discountValue, 2) || 0;
      }
      if (discountType === this.discountTypeValues.percentageOff) {
        percent_off = parseFloat(discountValue, 2) || 0;
      }
      this.$set(this.selectedItemProxy, 'discount', {
        amount_off,
        percent_off,
      });
    },
    editItem(items) {
      if (items && items.length === 0) return;
      this.$set(this.selectedItemProxy, 'id', items[0].id);
      this.$set(this.selectedItemProxy, 'label', items[0].label);
      this.$set(this.selectedItemProxy, 'price', items[0].price);
      this.itemToEdit = [];
    },
  },
};
</script>

<style scoped>
.buttonsDiv {
  display: flex;
}
.buttonActions {
  margin: 25px 0 0 20px;
}
</style>
