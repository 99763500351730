<template
  ><v-layout row wrap mt-4 mb-4>
    <v-card class="w100">
      <v-card-title primary-title>
        <div class="H5-Primary-Left display-flex w100 justify-space-between">
          <span>{{ heading }}</span>
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-icon class="icon clickable refresh-icon" @click="refetchData"
                    >mdi-refresh</v-icon
                  >
                </div>
              </template>
              <span>Refetch data</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-title>
      <div class="v-card-content">
        <v-layout>
          <v-flex xs12>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">
                  Select App
                </div>
                <v-select
                  :value="multigroupProxy"
                  class="dropdown-always-active-label"
                  :items="multigroups"
                  item-text="name"
                  label="App"
                  return-object
                  outline
                  :rules="multigroupRules"
                  :loading="loading"
                  @change="changeMultigroup"
                  ref="selectMultigroup"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout class="wrap">
              <v-flex xs5>
                <div class="H6-Secondary-Left mb-3">
                  {{ selectBrandLabel }}
                </div>
                <v-autocomplete
                  :value="companyProxy"
                  class="dropdown-always-active-label"
                  :items="companiesAvailable"
                  :item-text="(company) => company.name"
                  item-value="id"
                  label="Brands"
                  return-object
                  outline
                  :rules="companyRules"
                  @change="changeCompany"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout class="wrap f-column" style="padding-left: 4px;" v-if="showLocationFilter">
              <div class="H6-Secondary-Left mb-3">Filter by Location</div>
              <v-layout>
                <v-flex xs12 md3 style="padding-right:20px">
                  <v-select
                    v-model="selectedCountry"
                    class="dropdown-always-active-label"
                    :items="countriesAvailable"
                    label="Country (optional)"
                    outline
                    item-text="name"
                    item-value="id"
                    return-object
                    @change="changeCountry"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md3 style="padding-right:20px">
                  <v-select
                    v-model="selectedState"
                    class="dropdown-always-active-label"
                    :items="statesAvailable"
                    :label="stateLabel"
                    outline
                    item-text="name"
                    return-object
                    @change="changeState"
                  ></v-select>
                </v-flex>
                <v-flex xs12 md3>
                  <v-select
                    v-model="selectedCity"
                    class="dropdown-always-active-label"
                    :items="citiesAvailable"
                    label="City (optional)"
                    outline
                    item-text="name"
                    return-object
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-layout>

            <v-layout class="wrap">
              <v-flex xs10>
                <div class="H6-Secondary-Left mb-3">Select Sites</div>
                <span
                  class="Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                  v-if="selectSitesSubheading"
                >
                  {{ selectSitesSubheading }}
                </span>
                <site-filter
                  :description="siteFilterDesc"
                  :selectedSites.sync="location_groups"
                  :siteMap="sitesFilteredMap"
                  :showInDialog="false"
                  :noOffset="true"
                  :noShadow="true"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <span
              class="Body-2-Selected-On-Surface-Medium-Emphasis-Left"
              v-if="subHeading && company.name"
            >
              {{ subHeading }} <strong>{{ company.name }}</strong> {{ subHeading1 }}
            </span>
          </v-flex>
        </v-layout>
        <slot name="validation"></slot>
      </div>
    </v-card>
  </v-layout>
</template>

<script>
import rules from '@/rules';
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import provinces from '@/assets/provinces.json';
import SiteFilter from '@/components/Filter/SitesBase';

export default {
  components: {
    SiteFilter,
  },
  props: {
    heading: {
      type: String,
      required: false,
    },
    subHeading: {
      type: String,
      required: false,
    },
    subHeading1: {
      type: String,
      required: false,
    },
    multigroupsMap: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    multigroup: {
      type: Object,
      required: true,
    },
    company: {
      type: Object,
      required: true,
    },
    locationGroups: {
      type: Array,
      required: true,
    },
    siteFilterDesc: {
      type: String,
      required: false,
    },
    showLocationFilter: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectBrandLabel: {
      type: String,
      required: false,
      default: 'Select Brand',
    },
    confirmMultigroupChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmCompanyChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    multigorupResetConfirmMessageTitle: {
      type: String,
      required: false,
      default: 'Select App',
    },
    multigroupResetConfirmMessage: {
      type: String,
      required: false,
      default:
        'Changing App will clear your company selection and selected sites. Do you want to reset your selections?',
    },
    companyResetConfirmMessageTitle: {
      type: String,
      required: false,
      default: 'Select Brand',
    },
    companyResetConfirmMessage: {
      type: String,
      required: false,
      default:
        'Selecting a new brand will reset all selected sites. Do you want to reset your selections?',
    },
    selectSitesSubheading: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    selectedCountry: { name: '' },
    selectedState: {},
    selectedCity: '',
    multigroupRules: [rules.required('App is Required')],
    companyRules: [rules.required('Brand is Required')],
    companiesAvailable: [],
    loading: false,
  }),
  computed: {
    ...mapState('sites', ['multigroups', 'siteMap']),
    multigroupProxy() {
      return this.multigroups.find((m) => m.id === this.multigroup.id);
    },
    companyProxy() {
      return this.company && this.company.id;
    },
    countriesAvailable() {
      return [
        { name: '' },
        { name: 'United States', short: 'US' },
        { name: 'Canada', short: 'CA' },
      ];
    },
    statesAvailable() {
      if (!this.selectedCountry.name) return [];
      return [
        { name: '' },
        ...provinces.filter((province) => province.country === this.selectedCountry.short),
      ];
    },
    citiesAvailable() {
      const cities = [];
      if (!this.selectedCountry.name || !this.selectedState.name) return cities;
      const selectedMultigroup = this.multigroupsMap[this.multigroup.id];
      if (!selectedMultigroup || !selectedMultigroup.groups) return cities;

      const citiesAll = []
        .concat(...selectedMultigroup.groups)
        .map(
          (site) =>
            site.address &&
            site.address.country === this.selectedCountry.short &&
            site.address.state === this.selectedState.short &&
            this.capitalise(site.address.city),
        )
        .filter(Boolean)
        .sort();
      cities.push(...new Set(['', ...citiesAll]));
      return cities;
    },
    sitesFiltered() {
      const selectedMultigroup = this.multigroupsMap[this.multigroup.id];
      if (!selectedMultigroup || !selectedMultigroup.groups) return [];
      let sites = [].concat(...selectedMultigroup.groups);
      if (this.selectedCountry.short) {
        sites = sites.filter(
          (site) => site.address && site.address.country === this.selectedCountry.short,
        );
      }
      if (this.selectedState.short) {
        sites = sites.filter(
          (site) => site.address && site.address.state === this.selectedState.short,
        );
      }
      if (this.selectedCity) {
        sites = sites.filter(
          (site) => site.address && this.capitalise(site.address.city) === this.selectedCity,
        );
      }
      if (this.company && this.company.id) {
        sites = sites.filter((site) =>
          site.locations.some((l) => l.brands.some((b) => b.company === this.company.id)),
        );
      }
      return sites;
    },
    sitesFilteredMap() {
      return this.sitesFiltered.reduce((combined, next) => {
        combined[next.id] = next;
        return combined;
      }, {});
    },
    location_groups: {
      get() {
        if (!this.locationGroups || this.locationGroups.length === 0 || !this.sitesFilteredMap)
          return [];

        return this.locationGroups.map((group) => {
          const groupDetails = this.sitesFilteredMap[group.id];
          return {
            id: group.id,
            name: groupDetails && groupDetails.label && groupDetails.label.en,
          };
        });
      },
      set(value) {
        this.$emit('update:locationGroups', value);
      },
    },
    stateLabel() {
      if (this.selectedCountry.short === 'CA') return 'Province/UT (Optional)';
      return 'State (optional)';
    },
  },
  methods: {
    ...mapActions('sites', ['getLocationMultigroup']),
    ...mapActions('sectors', ['fetchSector']),
    async loadMultigroup(multigroupId, nocache = false) {
      const multigroup = await this.getLocationMultigroup({
        id: multigroupId,
        extended: true,
        expanded: true,
        merge: false,
        nocache: nocache ? true : undefined,
      });
      this.$set(this.multigroupsMap, multigroupId, multigroup);
    },
    async refetchData() {
      if (!this.multigroup) return;
      try {
        await this.loadMultigroup(this.multigroup.id, true);
        this.$toast.success('Data refetched successfully');
      } catch (err) {
        console.error(err);
        this.$toast.error('Could not refetch data');
      }
    },
    async changeMultigroup(value) {
      if (this.confirmMultigroupChange) {
        if (
          this.multigroup &&
          this.multigroup.id &&
          this.multigroup.id !== value.id &&
          (this.locationGroups.length > 0 || this.company.id)
        ) {
          const input = await this.$confirm({
            title: this.multigorupResetConfirmMessageTitle,
            message: this.multigroupResetConfirmMessage,
            buttonTrueText: 'OK',
            buttonFalseText: 'CANCEL',
          });
          if (!input) {
            const oldValue = this.multigroupProxy;
            this.$emit('update:multigroup', value);
            this.$nextTick(() => {
              this.$emit('update:multigroup', oldValue);
            });

            return;
          }
        }
      }
      this.$emit('update:multigroup', value);
      this.$emit('update:locationGroups', []);
      this.$emit('update:company', {});
      this.companiesAvailable = [];
      this.loadMultigroup(value.id);
    },
    async changeCompany(value) {
      if (this.confirmCompanyChange) {
        if (
          this.company &&
          this.company.id &&
          this.company.id !== value.id &&
          this.locationGroups.length > 0
        ) {
          const input = await this.$confirm({
            title: this.companyResetConfirmMessageTitle,
            message: this.companyResetConfirmMessage,
            buttonTrueText: 'OK',
            buttonFalseText: 'CANCEL',
          });
          if (!input) {
            const oldValue = this.companyProxy;
            this.$emit('update:company', value);
            this.$nextTick(() => {
              this.$emit('update:company', oldValue);
            });
            return;
          }
        }
      }
      this.$emit('update:company', value);
      this.$emit('update:locationGroups', []);
    },
    changeCountry() {
      this.selectedState = {};
      this.selectedCity = '';
    },
    changeState() {
      this.selectedCity = '';
    },
    capitalise(value) {
      const words = value && value.split(' ');

      return (
        words &&
        words
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(' ')
      );
    },
  },
  watch: {
    async sitesFiltered() {
      this.loading = true;
      const sectorIds = [];
      const companyIds = [];
      this.companiesAvailable = [];
      let selectedMultigroup = this.multigroupsMap[this.multigroup.id];

      if (!selectedMultigroup) {
        await this.loadMultigroup(this.multigroup.id, true);
        selectedMultigroup = this.multigroupsMap[this.multigroup.id];
      }
      selectedMultigroup.groups.forEach((site) => {
        if (!site.locations) return;
        site.locations.forEach((location) => {
          if (!location.brands) return;
          location.brands.forEach((brand) => {
            if (!brand.sector) return;
            if (!sectorIds.includes(brand.sector)) sectorIds.push(brand.sector);
            if (!companyIds.includes(brand.company)) companyIds.push(brand.company);
          });
        });
      });
      const sectorsFetched = await Promise.all(
        sectorIds.map((sector_id) => this.fetchSector({ sector_id, expanded: false })),
      );

      let companies = []
        .concat(...sectorsFetched.map((s) => s.companies))
        .filter((company) => companyIds.includes(company && company.id))
        .sort((a, b) => a.name && a.name.localeCompare(b.name));

      companies = cloneDeep(companies);

      companies.forEach((c) => {
        const sector = sectorsFetched.find((s) => s.id === c.sector);
        if (sector) c.name = `${c.label.en} (${sector.label.en})`;
      });
      this.companiesAvailable = companies;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
div >>> .refresh-icon:hover {
  color: #3a83a7;
}
div >>> .refresh-icon:active {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
</style>
